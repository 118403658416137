.google-login-button {
  font-family: "Montserrat";
  font-weight: bold;
  color: gray;
  width: 100%;
  border: 1px solid lightgray;
  margin: 10px 0px;
  padding: 10px 40px;
  width: 95%;
  margin:0 auto;
  margin-top: 5px;
  font-size: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: 0.2s;
  &:hover {
    filter: brightness(110%);
  }
  &:active {
    transform: scale(0.99);
    box-shadow: none;
  }
  svg {
    font-size: 1.2rem;
    margin-right: 4px;
  }
}
