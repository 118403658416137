.ag-icon {
  width: 2rem;
  height: 2rem;
}

.coupon-input-box::placeholder{
  font-size: 13.5px;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
    max-width: 600px;
    background-color: #fff;
    // backdrop-filter: blur(10px);
    // box-shadow: 0 -5px 10px rgba($color: #000000, $alpha: 0.1);
    margin: auto;
    // 0 0 0 0.1rem rgba($color: #000000, $alpha: 0.05);
    li {
      position: relative;
      // flex: 1;
      width: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 3.5rem;
      & > div {
        span {
          bottom: 0;
          position: absolute;
          font-size: 0.9rem;
          display: none;
        }
      }
      svg {
        transition: 0.3s;
        font-size: 1.2rem;
        color: rgba($color: #000000, $alpha: 0.4);
        color: #00000059;
        // box-shadow: 0 0 0 10px #fff;
      }
    }
    .selected {
      & > div {
        svg {
          z-index: 10;
          padding: 0.7rem;
          // border-radius: 3rem;
          color: $color-accent;
          font-size: 1.4rem;
        }
        span {
          display: block;
        }
      }
      // &::after {
      //   @include heightAnim(107%);
      //   content: '';
      //   position: absolute;
      //   bottom: 0;
      //   width: 100%;
      //   background-color: $color-accent;
      // }
    }
  }
  @include respond-to("md") {
    display: none;
  }
}

.outlet-panel {
  animation: popup 0.2s;
  position: absolute;
  top: 110%;
  left: 6rem;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1),
    0 5px 1rem rgba($color: #000000, $alpha: 0.1);
  max-width: 30rem;
  padding-bottom: 10px;
  // border-radius: 5px;
  &::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    top: 4px;
    transform: rotate(45deg) translate(-50%, -50%);
    left: 10%;
    box-shadow: -1px -1px 0 0 rgba($color: #000000, $alpha: 0.1);
  }
  &-top {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 1rem;
    gap: 1rem;
    background-color: #fff;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    align-items: flex-start;
    // color: #fff;
    .o-s {
      position: absolute;
      display: inline-flex;
      align-items: center;
      bottom: 1rem;
      right: 1rem;
      font-size: 0.7rem;
      padding: 0.4rem 10px;
      font-weight: 500;
      color: rgba($color: #fff, $alpha: 0.9);
      border-radius: 1rem;
      span {
        display: inline-block;
        font-weight: bold;
        color: #fff;
      }
      svg {
        margin: 0 5px;
      }
    }
    .o-map {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6rem;
      height: 6rem;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      > svg {
        font-size: 2rem;
        color: #999;
      }
      img {
        width: 100%;
        filter: invert(1);
      }
      > .v-m {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        font-size: 0.7rem;
        font-weight: 500;
        opacity: 0;
        transition: 0.2s;
        > svg {
          font-size: 1.3rem;
          margin-bottom: 5px;
        }
      }
      &:hover {
        box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1),
          0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        > .v-m {
          opacity: 1;
        }
      }
    }
    .o-det {
      padding: 5px 0;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 5px 10px;
      font-size: 0.9rem;
      p {
        margin-bottom: 5px;
      }
      > svg {
        font-size: 1rem;
      }
      > span {
        font-size: 0.8rem;
      }
      > div {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
  .o-search-head {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 1rem;
    font-size: 0.8rem;
    // color: $color-accent;
    font-weight: 500;
    svg {
      margin-right: 10px;
      font-size: 1rem;
      border-radius: 50%;
      overflow: visible;
      box-shadow: 0 0 0 1px #000;
      padding: 5px;
      // color: #fff;
      // background-color: #000;
    }
  }
  .o-search-panel {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 10px;
    margin: 0 10px;
    .no-sel {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #999;
      text-align: center;
      svg {
        font-size: 1.4rem;
      }
      span {
        display: block;
        white-space: nowrap;
        font-size: 0.8rem;
      }
    }
    ul {
      position: relative;
      margin: 0;
      padding: 0;
      height: 20rem;
      overflow-y: auto;
      // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      @include scrollBar-sm(5px);
      &:last-child {
        border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
      li {
        padding: 0.5rem 10px;
        font-size: 0.8rem;
        transition: 0.2s;
        &:first-child {
          position: sticky;
          top: 0;
          background-color: #fff;
          margin: 5px 0;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 1rem;
          svg {
            margin-right: 10px;
            color: #000;
          }
          & > span {
            position: absolute;
            right: 1rem;
            background-color: $color-accent;
            font-size: 0.7rem;
            font-weight: bold;
            color: #fff;
            padding: 2px 5px;
            border-radius: 1rem;
          }
        }
        & > svg {
          margin-right: 5px;
          color: #888;
        }
        .d-a {
          position: absolute;
          right: 0;
          font-size: 1rem;
          color: #fff;
        }
        &:hover:not(.selected):not(:first-child) {
          cursor: pointer;
          background-color: #fafafa;
        }
      }
      .selected {
        background-color: $color-accent;
        color: #fff;
        border-radius: 1rem;
        // margin-right: 5px;
        font-weight: bold;
        svg {
          color: #fff;
        }
      }
    }
  }
}

.header {
  position: sticky;
  top: 0;
  background: #fffffff0;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 1px 0 rgba(0, 0, 0, 0.1);
  padding: 0 1rem;
  height: 4rem;
  margin: 0;
  z-index: 20;
  transition: 0.2s;
  &-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    .a-logo {
      display: grid;
      place-items: center;
      font-weight: bold;
      color: #fff;
      font-family: "Montserrat";
      font-size: 0.6rem;
      border-radius: 30%;
      cursor: pointer;
      transition: 0.2s;
      img {
        width: 4rem;
        filter: invert(0.15);
        // object-fit: fill;
      }
      // text-align: center;
      &:hover {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
      }
    }
    .h-shop {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      margin: 0 1rem;
      .shop-loc {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 8px 10px 8px 10px;
        font-size: 0.9rem;
        height: 100%;
        // border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
        cursor: pointer;
        > svg {
          transition: 0.2s;
          &:first-child {
            margin-right: 1rem;
            font-size: 1.2rem;
          }
          &:last-child {
            margin-left: 1.5rem;
          }
        }
        > div {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          font-size: 0.8rem;
          > span {
            font-size: 0.6rem;
            color: #aaa;
          }
        }
        &--open {
          background-color: $color-accent;
          color: #fff;
          border-radius: 5px;
          box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
          > div {
            > span {
              color: #fff;
            }
          }
        }
        &:hover {
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        }
      }
      .shop-stat {
        padding: 5px 10px;
        box-shadow: 0 0 0 1px rgba($color: $color-accent, $alpha: 0.5);
        margin-left: 1rem;
        & > div {
          font-size: 0.7rem;
          font-weight: 500;
          svg {
            font-size: 0.8rem;
            margin-right: 5px;
            color: $color-accent;
          }
        }
      }
    }
  }
  &-right {
    .item-group {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      margin: 0;
      margin-right: 2vh;
      li {
        position: relative;
        margin-right: 20px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        padding: 10px;
        cursor: pointer;
        border-radius: 50px;
        color: #000;
        @include ttip-hover("bottom") {
          background-color: rgba($color: #000000, $alpha: 0.03);
        }
      }
    }
  }
  @include respond-to("md") {
    display: flex;
  }
}

.header-mobile {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  svg {
    font-size: 1.1rem;
  }
  &-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    .u-loc {
      display: inline-flex;
      align-items: center;
      p {
        margin: 0;
        box-sizing: border-box;
        img {
          width: 3.5rem;
          height: 3.5rem;
          filter: invert(0.15);
        }
      }
      span {
        display: inline-flex;
        font-size: 0.8rem;
        font-weight: bold;
        align-items: center;
        svg {
          margin-left: 5px;
          font-size: 0.8rem;
        }
      }
    }
    .u-f {
      margin-right: 1rem;
    }
    .u-c {
      position: relative;
      margin-right: 2rem;
      span {
        position: absolute;
        bottom: 50%;
        right: -1.1rem;
        display: grid;
        place-items: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        color: #fff;
        font-size: 0.9rem;
        box-shadow: 0 0 0 3px #fff;
        background-color: red;
        font-weight: bold;
        line-height: 1rem;
      }
    }
  }
  @include respond-to("md") {
    display: none;
  }
}

.product-card {
  position: relative;
  animation: pop-up 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  opacity: 0;
  display: flex;
  flex-direction: row;
  max-width: 35rem;
  width: 90%;
  align-items: flex-start;
  // border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  // padding-bottom: 1.5rem;
  * {
    box-sizing: border-box;
    font-family: "Montserrat";
  }
  .c-span {
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    background-color: $color-accent;
    font-size: 0.7rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    padding: 5px 0;
  }
  .f-sign {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    font-size: 1rem;
    background-color: #ffffff;
    padding: 2px;
    @include respond-below(md) {
      &.no-image {
        display: none;
      }
      &.after-title {
        // display: initial;
        position: initial;
        // margin-left: 0.3rem;
        vertical-align: middle;
        display: inline-block;
        height: 18px;
        width: 18px;
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
  &-top {
    position: relative;
    @include respond-below(md) {
      &.no-image {
        display: none;
      }
    }
    margin-right: 1rem;
    .p-c-img {
      display: grid;
      height: 5rem;
      width: 6rem;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      background-color: #fafafa;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        object-fit: cover;
        height: 100%;
        transition: 0.3s;

        // border-radius: 3px;
      }
    }
  }
  &-details {
    flex: 1;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 0;
    box-shadow: none;
    border-radius: 5px;
    z-index: 2;
    background-color: #fff;
    // margin-top: -10px;
    transition: 0.2s;
    .det-top {
      .p-name {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1rem;
        margin: 0;
        > span {
          font-weight: 500;
          vertical-align: middle;
          display: inline-block;
        }
      }
      .p-content {
        margin: 5px 0;
        font: 400 0.8rem "Cormorant Garamond";
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .p-price {
        font-weight: bold;
        color: $color-accent;
        span {
          text-decoration: line-through;
          font-weight: normal;
          font-size: 0.8rem;
          margin-right: 5px;
          color: #999;
        }
      }
    }
    .det-bottom {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      margin-top: 1rem;
      .p-actions {
        ul {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;
          padding: 0;
          cursor: pointer;
          li {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 5px;
            font-size: 1.2rem;
            & > svg {
              font-size: 1.3rem;
              box-sizing: content-box;
              padding: 2px;
              transition: 0.2s;
            }
          }
          .p-c-rating {
            display: inline-flex;
            align-items: center;
            font-size: 0.7rem;
            border-radius: 0;
            margin-left: 5px;
            cursor: default;
            svg {
              color: orange;
              padding: 0;
              margin-right: 0.3rem;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .p-allergens {
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 0;
      > div {
        svg {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
    .p-exp {
      @include popUp();
      display: inline-flex;
      position: absolute;
      bottom: 100%;
      left: -1rem;
      padding: 10px;
      background-color: #fff;
      border-radius: 3rem;
      box-shadow: 0 5px 1rem rgba($color: #000000, $alpha: 0.1);
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        left: 1.5rem;
        bottom: -1rem;
        border-width: 10px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
      }
      & > span {
        position: relative;
        font-size: 1.5rem;
        margin: 0 5px;
        span {
          position: absolute;
          font-size: 0.8rem;
          bottom: 100%;
          left: 0;
          background-color: #000;
          white-space: nowrap;
          color: #fff;
          padding: 2px 10px;
          border-radius: 1rem;
          margin-left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: 0.2s;
        }
        &:hover {
          opacity: 0.8;
          span {
            opacity: 1;
          }
        }
      }
    }
    .a-c-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      svg {
        box-sizing: content-box;
        box-shadow: 0 0 0 1px $color-accent;
        color: #fff;
        font-size: 1rem;
        border-radius: 2rem;
        padding: 0.6rem;
        transition: 200ms;
        background-color: #fff;
        color: $color-accent;
        &:hover {
          background-color: $color-accent;
          transform: rotate(90deg);
          color: #fff;
          box-shadow: none;
        }
        &:active {
          transform: rotate(90deg) scale(0.9);
        }
      }
      @include ttip-hover();
    }
  }
  &:hover & {
    &-top {
      position: relative;
      .p-c-img {
        img {
          transform: scale(1.1);
          filter: brightness(80%);
        }
      }
    }
    &-details {
      background-color: #fafafa;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1),
        0 0 0 1px rgba($color: #000000, $alpha: 0.05);
    }
  }
  &:hover {
    cursor: pointer;
  }

  @include respond-to("md") {
    flex-direction: column;
    max-width: 20rem;
    width: 100%;
    margin: 0 1.5rem 1.5rem 0;
    align-items: stretch;
    .f-sign {
      top: 1rem;
      left: 1rem;
      font-size: 1.2rem;
    }
    .c-span {
      top: auto;
      bottom: 0;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0.6rem 0 1rem 0;
    }
    &-top {
      position: relative;
      margin: 0;
      .p-c-img {
        height: 13.5rem;
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          height: 13rem;
          transition: 0.3s;
        }
      }
    }

    &-details {
      padding: 1rem;
      padding-bottom: 10px;
      margin-top: -10px;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      .det-top {
        .p-name {
          margin: 5px 0 1rem 0;
        }
        .p-content {
          margin: 5px 0;
          min-height: 2rem;
          font: 500 0.9rem "Cormorant Garamond";
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .det-bottom {
        .p-actions {
          ul {
            padding: 10px 0;
            .p-c-rating {
              font-size: 0.9rem;
            }
          }
        }
      }
      .p-allergens {
        > div {
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      .a-c-btn {
        right: 1rem;
        bottom: 1rem;
        svg {
          padding: 0.8rem;
        }
      }
    }
  }
}

.cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 450px;
  z-index: 400;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translateX(100%);
  .close-icon {
    position: relative;
    background-color: transparent;
    padding: 5px;
    border-radius: 40px;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #00000015;
    }
  }
  .err-txt {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: red;
    background-color: #ffe4e4;
    font-size: 0.8rem;
    z-index: 10;
    padding: 10px;
    svg {
      font-size: 1.2rem;
      margin-right: 10px;
    }
  }
  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    animation: popup 0.2s;
    p {
      font-weight: bold;
      color: #bbb;
      margin: 1rem 0;
    }
    img {
      width: 50%;
      opacity: 0.3;
    }
  }
  .top {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    padding-left: 2rem;
    background: #fff;
    color: #222;
    border-bottom: 1px solid #eee;
    & > div {
      font-weight: 500;
    }
  }
  .list {
    @include scrollBar-sm();
    align-items: center;
    overflow-y: auto;
    flex: 1;
    background: #fff;
  }
  .cart-card {
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 1rem 10px;
    column-gap: 1rem;
    background: #fff;
    font-family: "Montserrat";
    &-img {
      img {
        height: 5rem;
        width: 5.5rem;
        border-radius: 5px;
        object-fit: cover;
        font-size: 10px;
        background-color: #eee;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      }
      .counter-cont {
        .counter {
          margin: 0;
          height: 50px !important;
          margin-top: -1rem;
          span {
            min-width: 1rem;
          }
        }
      }
    }
    &-content {
      display: grid;
      grid-template-columns: 1fr auto;
      // padding: 1rem 0;
      position: relative;
      column-gap: 1rem;
      .name {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
        & > p {
          margin: 5px 0;
          margin-right: 0.5rem;
        }
      }
      .price {
        font-size: 0.9rem;
        font-weight: bold;
      }
      .con {
        margin: 0.5rem 0 0.8rem 0;
        font-size: 0.7rem;
        color: rgba($color: #000000, $alpha: 0.6);
        // font-family: 'Cormorant Garamond';
      }
      .cart-extra {
        display: flex;
        flex-direction: column;
        &-size {
          font-size: 0.7rem;
          padding: 2px 5px;
          color: $color-accent;
          box-shadow: 0 0 0 1px $color-accent;
          border-radius: 3px;
          display: inline-block;
        }
        &-addons {
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          font-size: 0.6rem;
          border-top: 1px solid $color-accent;
          padding: 10px 0;
          margin-top: 5px;
          font-weight: 500;
          p {
            position: absolute;
            top: -8px;
            left: 5px;
            padding: 0 3px;
            color: $color-accent;
            background-color: #fff;
            font-size: 0.7rem;
          }
          span {
            display: inline-block;
            background: #fff;
            color: #000;
            border-radius: 10px;
            cursor: default;
            i {
              margin-left: 3px;
              color: #000;
            }
          }
        }
      }
    }
    .cart-delete {
      padding: 0.6rem;
      // border-radius: 2rem;
      align-self: flex-end;
      color: #000;
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      right: -5px;
      &:hover {
        color: #d50000;
        // color: #fff;
      }
    }
    @include respond-to("md") {
      column-gap: 1.5rem;
      padding: 1rem 1.5rem;
      &-content {
        .price {
          font-size: 1rem;
        }
      }
      &-img {
        img {
          height: 5rem;
          width: 6rem;
          border-radius: 5px;
          object-fit: cover;
          font-size: 10px;
          background-color: #eee;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        }
        .counter-cont {
          .counter {
            margin: 0;
            height: 50px;
            margin-top: -1rem;
            span {
              min-width: 2rem;
            }
          }
        }
      }
    }
  }
  .ch-bottom {
    padding: 1rem 1.5rem;
    > div {
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      color: $color-accent;
      background-color: $color-accent;
      color: #fff;
      border-radius: 5px;
      min-height: 3.5rem;
    }
    .ch-btn {
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      padding: 1rem;
      border-radius: 2rem;
      font-weight: bold;
      &--disabled {
        color: rgba($color: #fff, $alpha: 0.5);
      }
      svg {
        font-size: 1.3rem;
      }
    }
    .ch-total {
      font-size: 0.5rem;
      margin: 0 0 0 1.5rem;
      border-right: 1px solid #ffffff56;
      span {
        display: inline-block;
        margin-right: 5px;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
}

.footer {
  margin-top: 2rem;
  background-color: #00061d;
  min-height: 30vh;
  padding: 1rem 2rem;
  padding-bottom: 4rem;
  * {
    box-sizing: border-box;
    font-family: "Montserrat";
  }
  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    margin: 2rem 0 2rem 0;
    .f-logo {
      img {
        // filter: invert(1);
        width: 10rem;
      }
      margin-bottom: 4rem;
    }
    & > div {
      display: inline-flex;
      align-items: center;
      color: #fff;
      p {
        display: grid;
        place-items: center;
        font: bold 1.4rem "Montserrat";
        width: 3rem;
        height: 3rem;
        border-radius: 35%;
        background-color: #ffffff33;
        padding-top: 5px;
        color: #fff;
        margin-right: 10px;
      }
      div {
        span {
          display: block;
          color: #ffffff89;
          // font-size: 0.9rem;
          &:first-child {
            font: bold 1rem "Montserrat";
            color: #fff;
          }
        }
      }
    }
    .f-inp {
      position: relative;
      align-items: stretch;
      label {
        display: inline-flex;
        position: absolute;
        top: -1.2rem;
        color: #ffffffa9;
        font-size: 0.7rem;
        svg {
          margin-right: 5px;
        }
      }
      input {
        color: #fff;
        background: rgba($color: #fff, $alpha: 0.1);
        box-shadow: 0 0 0 1px rgba($color: #fff, $alpha: 0.2);
        font-size: 1rem;
        margin-right: 1rem;
        padding: 10px;
        outline: none;
        &:focus {
          background: rgba($color: #fff, $alpha: 0.2);
        }
      }
      & > div {
        display: grid;
        place-items: center;
        // background-color: #fff;
        // color: #000;
        box-shadow: 0 0 0 1px #ffffffa9;
        font-size: 0.9rem;
        padding: 0 1rem;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
  &-mid {
    display: grid;
    grid-template-columns: 1fr;
    margin: 5rem 0;
    .f-t {
      display: block;
      font-size: 1rem;
      text-transform: uppercase;
      color: #ffffffa9;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 3rem;
      color: #ffffffa9;
      margin: 0;
      padding: 0;
      .l-m {
        display: flex;
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 3rem;
        & > span:not(.f-t) {
          color: #ffffff89;
          margin-top: 1rem;
          // font-size: 0.9rem;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .f-social {
      color: #ffffff69;
      max-width: 300px;
      p {
        margin: 1rem 0;
      }
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2rem;
        svg {
          font-size: 1.5rem;
          margin-right: 1.5rem;
          cursor: pointer;
          &:hover {
            color: #fff;
            filter: drop-shadow(0 0 10px #fff);
          }
        }
      }
    }
  }
  &-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 0.8rem;
    border-top: 1px solid #ffffff25;
    padding: 2rem 0 1rem 0;
    color: #ffffffa9;
    & > div {
      display: none;
      span {
        display: inline-block;
        margin: 0 1rem;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
    & > span {
      display: inline-flex;
      align-items: center;
      svg {
        font-size: 0.6rem;
        margin-right: 5px;
      }
    }
  }
  @include respond-to("md") {
    padding: 1rem 8rem;
    &-top {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .f-logo {
        img {
          width: 10rem;
        }
        margin-bottom: 0;
      }
    }
    &-mid {
      grid-template-columns: 1fr auto;
      margin: 3rem 0 5rem 0;
      ul {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3rem;
        .l-m {
          display: none;
        }
        li {
          margin-bottom: 0;
        }
      }
    }
    &-bottom {
      & > div {
        display: block;
        span {
          display: inline-block;
          margin: 0 1rem;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
        }
      }
      & > span {
        display: inline-flex;
        align-items: center;
        svg {
          font-size: 0.6rem;
          margin-right: 5px;
        }
      }
    }
  }
}

.mobile-menu {
  display: block;
  z-index: 310;
  position: fixed;
  bottom: 4rem;
  right: 10px;
  padding: 0;
  .cat-display {
    transition: 0.2s;
    z-index: 10;
    svg {
      padding: 1rem;
      background-color: $color-accent;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 50%;
      overflow: visible;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0 0 1px #ffffffa6;
    }
    &:active {
      transform: scale(0.95);
    }
  }
  .cat-list {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
    background-color: rgba($color: #000000, $alpha: 0.6);
    ul {
      animation: popup 0.2s;
      margin: 0;
      padding: 10px;
      background-color: #fff;
      max-width: 25rem;
      min-width: 15rem;
      box-shadow: 0 10px 1rem rgba($color: #000000, $alpha: 0.1);
      pointer-events: all;
      border-radius: 3px;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem 1.5rem;
        font-weight: bold;
        transition: 0.1s;
        border-radius: 3px;
        &:not(:last-child) {
          border-bottom: 1px solid #ffffff34;
        }
        svg {
          margin-right: 1rem;
        }
        &:active {
          background-color: #00000014;
        }
      }
      .selected {
        background-color: $color-accent;
        color: #fff;
        box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
  @include respond-to("md") {
    display: none;
  }
}
.mobile-top-filters {
  display: block;
  z-index: 310;
  position: fixed;
  // bottom: 4rem;
  right: 10px;
  padding: 0;
  .filter-list {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
    background-color: rgba($color: #000000, $alpha: 0.6);
    div.drop-down ul {
      z-index: 300;
    }
    div {
      animation: popup 0.2s;
      margin: 0;
      padding: 10px;
      background-color: #fff;
      max-width: 25rem;
      min-width: 15rem;
      // box-shadow: 0 10px 1rem rgba($color: #000000, $alpha: 0.1);
      pointer-events: all;
      border-radius: 3px;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem 1.5rem;
        font-weight: bold;
        transition: 0.1s;
        border-radius: 3px;
        // &:not(:last-child) {
        //   border-bottom: 1px solid #ffffff34;
        // }
        svg {
          margin-right: 1rem;
        }
        // &:active {
        //   background-color: #00000014;
        // }
      }
      .selected {
        background-color: $color-accent;
        color: #fff;
        box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
  .cat-filter-display {
    transition: 0.2s;
    z-index: 10;
    svg {
      padding: 1rem;
      background-color: $color-accent;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 50%;
      overflow: visible;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0 0 1px #ffffffa6;
    }
    &:active {
      transform: scale(0.95);
    }
  }
  @include respond-to("md") {
    display: none;
  }
}
.skeleton-anim {
  position: relative;
  background-color: #ddd;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: rotate(40deg);
    background: linear-gradient(to left, transparent, #fff, transparent);
    animation: reflectEffect 1s infinite;
  }
}
.skeleton {
  width: 40vh;
  height: 40vh;
  margin: 20px;
  // border-radius: 5px;
  background-color: #fff;
  animation: sk-anim-1 1s infinite alternate forwards;
  opacity: 0.3;
  span {
    position: relative;
    overflow: hidden;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   transform: rotate(40deg);
    //   background: linear-gradient(to left, transparent, #fff, transparent);
    //   animation: fadeOutAnim 1s infinite alternate;
    // }
  }
  & > span {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    background-color: #ddd;
  }
  > div {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    span {
      width: 50%;
      height: 0.7rem;
      margin: 10px;
      // border-radius: 1rem;
      background-color: #eee;
      /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); */
      &:first-child {
        width: 80%;
      }
      &:last-child {
        width: 60%;
      }
    }
  }
}
.react-datepicker {
  border: none;
  // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
  font-size: 0.9rem;
  // .react-datepicker__day--keyboard-selected:hover,
  // .react-datepicker__month-text--keyboard-selected:hover,
  // .react-datepicker__quarter-text--keyboard-selected:hover,
  // .react-datepicker__year-text--keyboard-selected:hover {
  //   background-color: $color-accent;
  // }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    background-color: transparent;
    color: #000;
    font-weight: normal;
  }
  .react-datepicker__day--selected {
    background-color: $color-accent;
    font-weight: bold;
    color: #fff;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    border-radius: 50%;
    outline: none;
  }
  .react-datepicker__header {
    // background-color: rgba($color: $color-accent, $alpha: 0.4);
    background-color: #fff;
  }
}

.counter-cont {
  flex-direction: row;
  animation: fadein 0.3s forwards;
  flex-direction: row-reverse;
  width: 100%;
  user-select: none;
  .counter {
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    align-items: center;
    text-align: center;
    overflow: hidden;
    height: 50px;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
    background-color: $color-accent;
    border-radius: 3px;
    color: #fff;
    svg {
      box-sizing: border-box;
      font-size: 1rem;
      min-width: 2rem;
      height: 93%;
      padding: 0.5rem 0;
    }
    span {
      font-size: 1.4rem;
      padding: 2px;
      min-width: 2rem;
    }
    .val {
      font-size: 1rem;
      font-weight: bold;
    }
    & > :not(.val) {
      cursor: pointer;
      &:hover {
        background-color: #00000026;
      }
      &:active {
        background-color: #00000056;
        transform: scale(0.96);
      }
    }
  }
}

.toggler {
  position: relative;
  font-size: 0.8rem;
  // box-shadow: 0 0 0 1px $color-accent;
  color: $color-accent;
  font-weight: bold;
  padding: 0.4rem 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  & > div {
    display: inline-flex;
    align-items: center;
    svg {
      margin-left: 8px;
      background-color: $color-accent;
      border-radius: 50%;
      color: #fff;
      box-shadow: 0 0 0 1px $color-accent;
    }
  }
  &:hover {
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
  }
}

.drop-down {
  position: relative;
  width: 160px;
  &-pl {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    min-width: 9.5rem;
    font-size: 0.9rem;
    p {
      flex: 1;
      margin-right: 0.5rem;
      font-size: 0.8rem;
    }
    span {
      display: block;
      flex: 1;
      color: rgba($color: #000000, $alpha: 0.4);
      font-weight: 500;
      font-size: 0.8rem;
    }
    > svg {
      font-size: 1rem;
      transition: 0.2s;
      color: $color-accent;
      margin-right: 0.5rem;
    }
    .d-arr {
      margin: 0;
    }
    .d-clear {
      position: absolute;
      opacity: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.1rem;
      background-color: #fff;
      border-radius: 50%;
      &:hover {
        color: #fff;
        background-color: $color-accent;
      }
    }
    &:hover {
      background-color: #fafafa;
      .d-clear {
        opacity: 1;
      }
      // box-shadow: 0 0 0 1px $color-accent;
    }
  }
  ul {
    position: absolute;
    top: 102%;
    min-width: 100%;
    background-color: #fff;
    padding: 5px 0;
    margin: 0;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
    // border-radius: 2px;
    animation: popup 0.2s;
    li {
      cursor: pointer;
      font-size: 0.85rem;
      padding: 0.6rem 1rem;
      &:hover:not(.o-active) {
        background-color: #fafafa;
        color: $color-accent;
      }
    }
  }
  .o-active {
    color: $color-accent;
    font-weight: bold;
    background-color: #{$color-accent}13;
  }
  .p-active {
    position: absolute;
    color: $color-accent;
    font-size: 0.7rem;
    background-color: #fff;
    z-index: 2;
    top: -0.6rem;
    left: 0.3rem;
    padding: 0 5px;
  }
}

@media (max-width: 412px) {
  .t-overlay-body > div {
    padding: 3px;
  }
  .react-datepicker__day {
    margin: 1px;
  }
}

@media (max-width: 350px) {
  .t-overlay-body > div:nth-child(2) > span {
    padding: 10px 0px;
  }
}
@media (max-width: 979px) {
  .s-c-loc {
    background-color: white !important;
    width: 2rem !important;
    height: 2rem !important;
    margin-top: 8px;
    margin-right: 10px;
    padding: 6px !important;
  }
  .search-cont .s-back {
    position: relative !important;
  }
  .search-cont .s-back .s-panel {
    margin-top: 1rem;
    width: 84%;
  }
}

.counter svg {
  height: 50% !important;
}

// mobile-offers-menu

// .mobile-offers-menu {
//   display: none !important;
// }
// .desktop-mobile-offers {
//   display: none !important;
// }

@media (min-width: 500px) {
  .mobile-offers-menu {
    display: none !important;
  }
  .offer-skelton {
    display: none !important;
  }
  // .mobile-offers{
  //   display: none !important;
  // }
}

@media (max-width: 600px) {
  .desktop-mobile-offers {
    display: none !important;
  }
}
.desktop-offers {
  display: flex;
  margin-top: 1rem;
  justify-content: flex-start;
}
.desktop-offer-cards {
  width: 80px;
  height: 80px;
  // background: linear-gradient(to top, #7474bf, #348ac7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: var(--dynamic-colour);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  border-radius: 50%;
  cursor: pointer;

  p {
    font-size: 0.8rem;
    font-weight: 550;
  }
}

.react-responsive-modal-modal {
  width: 400px !important;
}

.confirm-add {
  height: 2.8rem;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0px 1.6rem;
  border: 1px solid rgb(207, 207, 207);
  cursor: pointer;
  margin: 1rem 0px;
  font-size: 0.8rem !important;
  font-family: "Montserrat";
  font-weight: 600 !important;
}

.delivery-text {
  font-size: 1rem;
  line-height: 50%;
  color: rgb(79, 79, 79);
  text-transform: uppercase;
  font-weight: 600;
  margin: 2rem 0px 0.2rem;
}

.cpf-btn {
  display: flex;
  justify-content: center;
  background-color: var(--dynamic-colour);
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
}

.add-wrapper {
  .ad-add-btn {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: var(--dynamic-colour);
    color: #fff;
  }
}

.new-badge {
  background-color: #fff;
  color: var(--dynamic-colour);
  padding: 0.2rem;
  font-size: 0.8rem;
  border-radius: 1rem;
  align-items: flex-end;
}

// Table
// table style

#table-wrapper {
  .t-title {
    // position: relative;
    display: block;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
}
.table-nut {
  table {
    width: 100%;
    font-size: 0.8rem;
    // max-width: 240px;
    border-collapse: collapse;
  }
  /* Zebra striping */
  tr:nth-of-type(odd) {
    // background: #eee;
  }
  th,
  td {
    padding: 6px 0;
  }
  th {
    text-align: start;
    font-weight: inherit;
  }
  td {
    // padding: 6px;
    text-align: right;
    // border: 0.5px solid #ccc;
    // text-align: center !important;
  }
}

.cookiesContent {
  // width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px 70px;

  img {
    width: 82px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .center_image {
    // filter: drop-shadow(0 0 0.75rem var(--dynamic-colour));
    width: 120px;
  }
}

.customOverlay {
  // background: var(--dynamic-colour) !important;
  border-radius: 1rem !important;
}
.customModal {
  background: #b2dbbf !important;
  max-width: 500px;
  width: 100%;
  border-radius: 1rem;
}

.cookiesContent {
  h2 {
    font-family: "montserrat" !important;
  }
}
.points-text {
  h4 {
    font-family: "montserrat" !important;
    font-size: 1.2rem;
  }
}

.desktop_safety {
  margin-top: 1rem;
}

@media screen and (min-width: 799px) {
  .desktop_safety {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
