.v-slider {
  .v-cont {
    display: flex;
    flex-direction: row;
    max-width: 25rem;
    overflow-x: auto;
    padding: 0;
    @include scrollBar-sm() {
      height: 0;
    }
  }
  .arrow {
    position: absolute;
    width: 4rem;
    height: 100%;
    z-index: 10;
    top: 0;
    bottom: 5px;
    right: 0;
    pointer-events: none;
    background: none;
    box-shadow: inset -5px 0 2px rgba(0, 0, 0, 0.05);
    svg {
      display: none;
      position: absolute;
      pointer-events: all;
      cursor: pointer;
      right: -5px;
      top: 50%;
      transform: translateY(-60%);
      font-size: 0.8rem;
      padding: 8px;
      border-radius: 50%;
      color: $color-accent;
    }
    &-left {
      left: 0;
      background: none;
      box-shadow: inset 5px 0 2px rgba(0, 0, 0, 0.1);
      svg {
        left: 0;
      }
    }
  }
  @include respond-to("md") {
    .arrow {
      width: 2rem;
      background: linear-gradient(to left, #fff 80%, transparent);
      box-shadow: none;
      svg {
        display: block;
      }
      &-left {
        background: linear-gradient(to right, #fff 80%, transparent);
      }
    }
  }
}

//Login dialog
*:not(svg) {
  box-sizing: border-box;
}
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem !important;
  border-radius: 10px;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  // box-shadow: 0 1rem 1rem rgba($color: #000000, $alpha: 0.3);
  .img-back {
    display: none;
  }
  .l-btn {
    width: 95%;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    background-color: $color-accent;
    color: #fff;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
    padding: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      filter: brightness(110%);
    }
    &:active {
      transform: scale(0.99);
      box-shadow: none;
    }
  }
  .l-input {
    font-family: "Montserrat";
    margin-top: 3rem;
    label {
      display: none;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      color: #777;
      svg {
        font-size: 1rem;
        margin-right: 5px;
      }
    }
    input {
      font-family: "Montserrat";
      outline: none;
      width: 100%;
      font-size: 1rem;
      border-radius: 5px;
      box-shadow: 0 0 0 1px $color-accent;
      padding: 0.7rem;
      font-weight: 500;
      letter-spacing: 0px;
      &::-webkit-inner-spin-button {
        appearance: none;
      }
      &:focus {
        box-shadow: 0 0 0 2px $color-accent;
      }
    }
  }
  .l-ch {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    width: 100%;
    font-size: 0.9rem;
    span {
      display: inline-flex;
      padding: 0 10px;
      background-color: #fff;
      z-index: 20;
    }
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      top: 40%;
      content: "";
      border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
    }
  }
  .ph-inp {
    & > div {
      position: relative;
      width: 95%;
      margin: 0 auto;
      span {
        display: inline-flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        font-size: 1.3rem;
        font-weight: 500;
        color: $color-accent;
        svg {
          color: $color-accent;
          // border-right: 1px solid $color-accent;
          // margin-right: 5px;
          padding-right: 5px;
        }
      }
      input {
        padding-left: 2.3rem;
      }
    }
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10;
    // margin: 0.5rem 0;
    img {
      width: 10rem;
      height: 6rem;
      object-fit: contain;
      margin-bottom: 1rem;
      // filter: invert(1);
    }
    & > div {
      span {
        display: block;
        font-size: 0.8rem;
        &:first-child {
          font: bold 1rem "Montserrat";
        }
      }
    }
  }
  .l-head {
    margin-top: 2rem;
    position: relative;
    font: 500 1.6rem "Montserrat";
  }
  &-body {
    margin-top: 0;
    width: 100%;
    max-width: 500px;
  }
  &-social {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > div {
      // width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      font-weight: 500;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 1rem;
      }
      img {
        width: 2rem;
        height: 2rem;
      }
      svg {
        font-size: 2rem;
      }
      &:nth-child(3) {
        background-color: hsl(214, 100%, 59%);
        color: #fff;
        box-shadow: none;
      }
      &:nth-child(1) {
        background-color: #2196ff;
        color: #fff;
        box-shadow: none;
      }
    }
  }
  .pincode-input-text {
    width: 15% !important;
  }
 
  @include respond-to("md") {
    position: relative;
    width: 400px;
    height: auto;
    padding: 3rem;
    overflow: hidden;

    .l-head {
      display: none;
      font-size: 1.5rem;
      font-weight: bold;
      color: $color-accent;
    }
    &-top {
      top: 0;
      margin: 0;
      margin-top: 1rem;
    }
    &-body {
      margin: 0;
      // margin-top: 1rem;
    }
  }
}
.input-color {
  color: $color-accent;
}
.customize-cont {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 400;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  animation: fadein 0.2s;
  .divider {
    display: block;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
    margin: 10px 0;
  }
  .customize {
    position: relative;
    max-width: 30rem;
    max-height: 100vh;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    animation: reveal 0.2s;
    z-index: 10;
    padding: 1.5rem;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 1rem 1rem rgba($color: #000000, $alpha: 0.1);
    .custom-load {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
      z-index: 100;
    }
    .close-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      z-index: 10;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1.3rem;
      background-color: #fff;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      .progress {
        padding: 10px;
        border: 5px solid #00c8532d;
        position: absolute;
        align-self: center;
        top: 50%;
        border-radius: 100%;
        border-bottom-color: #00c853;
        animation: rotate 0.5s infinite linear;
      }
      .no-custom {
        font-size: 11px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
        padding: 5% 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
      &-head {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 1rem;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 5px;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
          object-fit: cover;
          overflow: hidden;
          background-color: #fafafa;
        }
        .details {
          span {
            display: block;
            font-size: 1.2rem;
            font-weight: 500;
          }
          p {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 5px;
          }
        }
      }
      .group {
        position: relative;
        margin-top: 1rem;

        & > span {
          display: block;
          margin: 1rem 0 10px 0;
          color: #777;
          font-weight: bold;
          font-size: 0.8rem;
        }
        .spacer {
          padding: 0 2rem;
        }
        .prod-item {
          margin-right: 1rem;
          white-space: nowrap;
          cursor: pointer;
          transition: 0.2s;
          opacity: 0;
          font-size: 0.8rem;
          animation: popup 0.2s forwards;
          padding: 10px 1rem;
          border-radius: 5px;
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          &:hover:not(.prod-item-selected) {
            background-color: #{$color-accent}25;
            color: $color-accent;
          }
          &:last-child {
            margin-right: 5rem;
          }
        }
      }
    }

    .bottom-bar {
      width: 100%;
      & > div {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        width: 100%;
        background: $color-accent;
        color: #fff;
        transition: 0.2s;
        border-radius: 0;
        box-shadow: none;
        font-weight: bold;
        svg {
          font-size: 1.5rem;
          margin-right: 10px;
        }
        &:hover {
          filter: brightness(110%);
        }
      }
    }
  }
  .disable {
    color: rgba($color: #fff, $alpha: 0.5);
  }
  .add-ons-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    // overflow-x: hidden;
    max-height: 50vh;
    min-height: 20rem;
  }
  .no-addon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #ccc;
    svg {
      font-size: 3rem;
    }
  }
  .add-ons-group {
    position: relative;
    display: flex;
    flex-direction: column;
    .add-ons {
      animation: fadein 0.5s;
      position: relative;
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          position: relative;
          cursor: pointer;
        }
        .add-on-item {
          padding: 0.5rem 1rem;
          border-radius: 2rem;
          margin: 1px 0.5rem 0.5rem 1px;
          animation: popup 0.2s;
          transition: 0.2s, transform 0.1s ease;
          padding-left: 2rem;
          font-size: 0.8rem;
          font-weight: 500;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          &:hover {
            box-shadow: 0 0 0 1px $color-accent;
          }
          &:active {
            background-color: #eee;
          }
          & > :first-child {
            position: absolute;
            left: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.2rem;
          }
          &--selected {
            // background: #{$color-accent}12;
            color: $color-accent;
            box-shadow: 0 0 0 1px $color-accent;
          }
        }
      }
    }
    p {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 10;
      padding: 1rem 0;
      font-size: 0.9rem;
      color: #888;
    }
  }

  .prod-item-selected {
    background: $color-accent;
    color: #fff;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.2);
  }

  @include respond-to("md") {
    align-items: center;
    .customize {
      max-width: 28rem;
      max-height: 100vh;
      border-radius: 5px;
      .content {
        &-head {
          column-gap: 1.5rem;
          img {
            width: 5rem;
            height: 5rem;
          }
          .details {
            span {
              display: block;
              font-size: 1.5rem;
              font-weight: 500;
            }
            p {
              font-size: 1.1rem;
              font-weight: 500;
              margin-bottom: 5px;
            }
          }
        }
        .group {
          .prod-item {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

#recaptcha-container {
  margin: 10px 0;
  position: absolute;
  align-self: center;
  bottom: 5px;
}

.order-tracker {
  background-color: #fff;
  border-radius: 10px;
  max-height: 400px;
  padding: 1.5rem !important;
  max-width: 500px;
  margin: auto;
  .seperator {
    border-top: 2px solid rgba($color: #000000, $alpha: 0.2);
    margin: 0.5rem 0;
    width: 37%;
    margin-left: auto;
    margin-right: auto;
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2rem;
    svg {
      margin-right: 4px;
    }
  }
  .o-load {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffffb9;
    z-index: 600;
  }
  .o-nav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 4.5rem;
    left: 1rem;
    right: 1rem;
    svg {
      // background-color: #000000;
      // color: #fff;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1),
        0 2px 5px rgba($color: #000000, $alpha: 0.1);
      padding: 10px;
      border-radius: 50%;
    }
  }
  &-main {
    .o-inp-cont {
      animation: fadeInAmin 0.3s;
      img {
        width: 100%;
        height: 10rem;
        object-fit: contain;
        margin: 0.2rem auto;
      }
      input {
        box-sizing: border-box;
        width: 100%;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
        padding: 10px;
        font-size: 1rem;
        // padding-left: 3.5rem;
        font-family: "Montserrat";
        border-radius: 3px;
      }
      .t-inp {
        position: relative;
        width: 100%;
        margin-top: 0.5rem;
        span {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.3rem;
        }
      }
      .t-btn {
        position: relative;
        background-color: $color-accent;
        margin: 1rem 0;
        text-align: center;
        max-width: 180px;
        border-radius: 30px !important;
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        padding: 1rem;
        font-weight: bold;
        margin-bottom: 0;
        border-radius: 3px;
        cursor: pointer;
        svg {
          position: absolute;
          right: 1rem;
          font-size: 1.3rem;
        }
      }
    }

    .order-det {
      animation: popup 0.3s;
      .o-d-head {
        margin-bottom: 1rem;
        p {
          font-weight: 500;
          text-align: center;
          span {
            font-size: 0.7rem;
            display: block;
            color: #999;
            margin: 5px 0;
          }
        }
      }
      .o-stat {
        position: relative;
        margin: 0;
        padding: 0;
        margin-top: 2rem;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 1rem;
          border-right: 1px solid rgba($color: #000000, $alpha: 0.2);
        }
        li {
          position: relative;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 1rem;
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
          > div {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 1rem;
            z-index: 5;
            svg {
              font-size: 1rem;
              padding: 0.5rem;
              box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
              background-color: #fff;
              border-radius: 50%;
              transition: 0.2s;
            }
            > p {
              margin: auto 0;
              font-size: 1rem;
              font-weight: bold;
              span {
                display: none;
                font-size: 0.7rem;
                font-weight: 500;
              }
            }
          }
          > span {
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
        .o-mask {
          position: absolute;
          background-color: #ffffffa9;
          width: 100%;
          height: 100%;
        }
        .o-done {
          .o-mask {
            display: none;
          }
        }
        .o-active {
          .o-mask {
            display: none;
          }
          > div {
            > p {
              color: $color-accent;
              span {
                display: block;
                color: #666;
              }
            }
            svg {
              animation: rippleBox 2s infinite;
              background-color: $color-accent;
              box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
              color: #fff;
            }
          }
        }
      }
    }
  }
  @include respond-to("md") {
    padding: 3rem;
    width: 400px;
    .o-nav {
      top: 2rem;
      left: 2rem;
      right: 2rem;
      svg {
        cursor: pointer;
      }
    }
  }
}
.or {
  // font-family: "Montserrat";
  margin-top: 10px;
  display: block;
  text-align: center;
  // font-weight: bold;
  color: gray;
}
