.dashboard {
  /* display: flex; */
  /* flex-direction: column; */
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  overflow: hidden;
  animation: fadein 0.7s;
}

@keyframes rot {
  0% {
    transform: rotate(10deg);
    opacity: 0;
  }
  50% {
    transform: rotate(-2deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}

.home {
  position: relative;
  flex-wrap: wrap;
  /* display: flex; */
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: flex-start;
  background: #fff;
  /* flex: 1; */
  overflow-y: auto;
}

.home .products {
  display: flex;
  flex-direction: row;
  /* grid-template-columns: repeat(auto-fit,60vh); */
  /* padding: 10px; */
  /* width: 100%; */
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  margin-left: 2%;
  /* margin-top: 1%; */
  /* min-height: 100vh; */
}
.home .products .menu-search-cont {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffffffe6;
  width: 90%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.menu-search-cont .menu-search-bar {
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 50px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.05); */
}
.menu-search-cont .menu-search-bar input {
  padding: 15px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: 'Montserrat';
  background-color: transparent;
  transition: 0.2s;
  width: 150px;
}
.menu-search-cont .menu-search-bar input:focus {
  width: 200px;
}
.menu-search-cont .menu-actions {
  display: inline-flex;
  align-items: center;
}
.menu-search-cont .menu-actions > span {
  display: inline-flex;
  align-items: center;
  /* cursor: pointer; */
  transition: 0.2s;
}
.menu-search-cont .menu-actions > span > *:first-child {
  margin-right: 10px;
}
.menu-search-cont .menu-filter > :last-child {
  margin-left: 10px;
}
.menu-actions .menu-veg-only {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.menu-actions .menu-veg-only .veg-info {
  position: absolute;
  padding: 10px;
  white-space: nowrap;
  top: 140%;
  right: 0;
  background-color: #388e3c;
  color: #fff;
  font-weight: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  animation: indicator 1s 3 forwards;
}
.menu-actions .menu-veg-only .veg-info::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 70%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #388e3c transparent;
}
.menu-search-cont .menu-actions .menu-filter {
  margin: 0 20px;
  padding: 10px 15px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
}
.menu-search-cont .menu-actions .menu-filter:hover {
  background-color: #00000012;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
.home::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}
.home::-webkit-scrollbar-thumb {
  background-color: #999;
  /* margin: 2px; */
  border-radius: 2rem;
}
.home .products .product-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
}

.home .products .c-category {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 2% 1rem;
}

.home .products .c-category p {
  position: relative;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Montserrat';
  /* margin: 10px 0; */
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.home .products .c-category p:hover::after {
  width: 100%;
}
.home .products .c-category p:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 80px;
  padding: 1px;
  /* border-radius: 10px; */
  background: #ffd600;
  transition: 0.2s;
}
.home .products .c-category:nth-child(1) {
  background: red;
}

/*Category*/
.home .category {
  /* position: sticky; */
  /* top: 15%; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  /* justify-content: center; */
  /* margin: auto; */
  font-family: 'Montserrat';
  /* background: #fff; */
  /* padding: 10px; */
  min-width: 15rem;
  box-shadow: 1px 0 0 1px rgba(0, 0, 0, 0.05);
}
.home .category .current-loc {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
  background: #2962ff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.home .category .cat-cont {
  position: sticky;
  padding-left: 10px;
  top: 10px;
  /* display: none; */
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  /* padding: 5px; */
  background: #fff;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.05); */
  animation: reveal 0.2s forwards;
  max-height: 90vh;
}
.home .category .cat-cont::-webkit-scrollbar {
  width: 5px;
}
.home .category .cat-cont::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}

.home .category .cat-item {
  position: relative;
  padding: 1rem;
  /* padding-right: 10px; */
  font-size: 0.9rem;
  border-radius: 50px 0 0 50px;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* font-weight: 500; */
  color: #000;
  white-space: nowrap;
  border: 1px solid transparent;
}
.home .category .cat-item *:nth-child(1) {
  margin-right: 20px;
  padding: 5px;
  background-color: transparent;
  border-radius: 50%;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}
/* .home .category .cat-item *:nth-child(2) {
  margin-left: 10px;
  position: absolute;
  right: 10px;
  padding: 5px;
  color: #fff;
  border-radius: 50%;
} */
.home .category .cat-item:active {
  transform: scale(0.98);
  opacity: 0.7;
  /* animation: smoothSlide 0.2s ease; */
}
.home .category .cat-menu {
  display: flex;
  flex-direction: row;
  /* color: #000; */
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  color: #000;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  /* position: sticky;
  top: 10px; */
  font-weight: bold;
  margin-bottom: 5px;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); */
}
.home .category .cat-menu *:first-child {
  padding: 10px;
  /* background: linear-gradient(to bottom, #000, #424242); */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  /* color: #fff; */
  border-radius: 50%;
}
/* .home .category .menu-ind{
  padding: 10px;
  background-color: #000;
  z-index: 100;
  position: absolute;
  right: 5px;
  border-radius: 50%;
} */
.cat-active {
  border-color: #00000056;
  /* background-color: #fff; */
  /* background: linear-gradient(to bottom right, #000, #424242); */
  /* border-left-color: #000; */
  animation: popup 0.2s;
  /* color: #fff; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  font-weight: bold;
  z-index: 5;
}
.cat-active *:nth-child(1) {
  background-color: #fff;
}
.home .category .cat-item:hover:not(.cat-active) {
  /* transform: translateX(3px); */
  background: rgba(0, 0, 0, 0.05);
}

/* .home .cat-name{
    padding: 10px;
    margin-left: 10%;
    margin-top: 2%;
    font-size: 40px;
    font-weight: bolder;
    color: #d50000;
    border-bottom: 1px solid rgba(255,0,0,0.2)
} */

/* Landing Page  Root Component */
.root {
  animation: fadein 0.4s;
  position: relative;
  overflow-y: hidden;
}
.root .new-feature {
  width: 20rem;
  min-height: 5rem;
  position: fixed;
  z-index: 10;
  bottom: 1rem;
  right: 1rem;
  background-color: #000;
  border-radius: 10px;
  padding: 1rem;
  animation: popup 0.4s 0.5s forwards;
  opacity: 0;
  /* display: flex; */
  /* flex-direction: row;
  align-items: flex-start;
  justify-content: center; */
  font-family: Montserrat;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.root .new-feature img {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}
.root .new-feature .go-to-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 1.3rem;
  background-color: #ffd600;
  border-radius: 3rem;
  font-weight: bold;
  transition: 0.2s;
  /* float: right; */
  margin: 10px 0;
}
.root .new-feature .go-to-button > :last-child {
  margin-left: 5px;
}
.root .new-feature .go-to-button:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.root .new-feature div:first-child > * {
  background-color: #ffd600;
  color: #000;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 50%;
  margin: 0.3rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* animation: clipAnim 0.5s 0.5s; */
}
.root .new-feature > div:nth-child(2) {
  margin: 0 1rem;
}
.root .new-feature h2 {
  margin: 1rem 0;
  margin-bottom: 5px;
  color: #fff;
}
.root .new-feature p {
  margin-bottom: 10px;
  padding: 0;
  color: #ffffffa6;
  /* font-weight: 700; */
  /* color: rgba(0, 0, 0, 0.5); */
  animation: popup 0.5s 1s forwards;
  opacity: 0;
}
.root .new-feature .close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 5px;
  border-radius: 50%;
  color: #ffffff97;
}
.root .new-feature .close-icon:hover {
  background-color: #ffffff43;
}
.root .root-header .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  /* background: #ffffff33; */
}
.root .heading,
.root .sub-heading {
  text-transform: uppercase;
  font-weight: bold;
  margin: 5vh 0 0 150px;
  font-size: 4vh;
  color: #ff9100;
}
.root .heading {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.root .sub-heading {
  font-size: 2vh;
  margin: 0vh 150px 5vh 150px;
  color: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.root .root-header {
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 10;
}
.root .root-header .back-img {
  object-fit: cover;
  z-index: 1;
  height: 100%;
  width: 100%;
  filter: blur(2px);
}
.root .root-header .title img {
  position: relative;
  width: 20vh;
  height: 20vh;
  z-index: 20;
  object-fit: contain;
}

.root .root-header .title {
  position: absolute;
  top: 5%;
  left: 5%;
  /* background:#fff ; */
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  /* border:2px solid red; */
  /* box-shadow: 0 15px 10px 0px rgba(0, 0, 0, 0.4); */
  box-sizing: border-box;
  z-index: 10;
}
.root .root-header .root-input {
  z-index: 10;
}

.root-header .root-input {
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.root .root-header .menu-bar {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  z-index: 10;
  font-size: 1rem;
  /* background-color: #000; */
  padding: 3rem 6rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
/* .root .root-header .menu-bar *:hover {
  color: #ffa726;
} */
.root .root-header .menu-bar .menu {
  color: #fff;
  text-decoration: none;
  /* margin-right: 2vh; */
}
.root .root-header .menu-bar .sign-up {
  color: #000;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  transition: 0.2s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1rem;
  background-color: #ffffff35;
  outline: none;
}
.root .root-header .menu-bar .sign-up:hover {
  color: #222;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.root .root-header .menu-bar .sign-in {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
}
.root .root-copy {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ffffff66;
  z-index: 10;
}

.features {
  display: flex;
  flex-direction: column;
}
.features .f-item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 5px 5px 0px rgba(255, 57, 22, 0.2);
  margin: 5px 10px;
  width: 60vw;
  border-radius: 5px;
  border: 1px solid rgba(255, 57, 22, 0.5);
  align-self: center;
  transition: transform 0.2s ease;
}
.features .f-item .main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.features .f-item .main p {
  align-self: center;
  font-weight: bold;
  text-align: center;
  font-size: 3vh;
  color: #e65100;
}
.features .f-item:hover {
  transform: translateY(-5px);
}
.features .f-item .desc {
  margin-top: 10px;
  padding-top: 10px;
  font-size: 2vh;
  border-top: 1px dotted #e65100;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.select-outlet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-outlet ul {
  position: absolute;
  padding: 10px 20px;
  padding-bottom: 20px;
  background: #fff;
  border-radius: 5px;
  min-width: 300px;
  /* width: 40%; */
  animation: fadein 0.2s forwards;
  box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.1);
}
.select-outlet ul li:first-child {
  color: rgba(0, 0, 0, 0.7);
  border: none;
  box-shadow: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  cursor: default;
  margin: 0 10px;
  padding: 20px 0 15px 0;
}
.select-outlet ul li:first-child:hover {
  background: none;
}
.select-outlet ul li {
  list-style-type: none;
  padding: 20px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  /* box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  border-radius: 3px;
  transition: 0.2s;
  /* font-weight: bold; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}
.select-outlet ul li .icon {
  padding: 5px;
  border-radius: 50%;
  background: #d50000;
  color: #fff;
  margin-right: 20px;
  align-self: flex-start;
}
.select-outlet ul li:hover {
  background: rgba(0, 0, 0, 0.05);
}
.select-outlet ul li p:first-child {
  flex: 1;
}
.select-outlet ul li p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
}
.select-outlet ul li p:last-child {
  font-size: 10px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}
.select-outlet ul li p span:nth-child(2) {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 5px;
  text-align: left;
}

@media (min-width: 992px) {
  .features {
    flex-direction: row;
    padding: 0 10vw;
  }
}

@media only screen and (max-width: 500px) {
  .root .heading,
  .root .sub-heading {
    margin: 5vh 0 0 15px;
  }
  .header .left .title span {
    margin: 0;
  }
  .root .sub-heading {
    margin: 0vh 15px 5vh 15px;
  }
  .root .root-header .title img {
    height: 15vh;
  }
  .home .products .menu-search-cont {
    background-color: #fafafada;
    width: 100%;
    justify-content: center;
  }
  .home .products .menu-search-bar {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0 1rem;
    flex: 1;
  }
  .home {
    justify-content: center;
    display: flex;
    background: #fafafa;
  }
  .header .item-group > span {
    margin-right: 20px;
    padding: 0px;
    border-radius: 50px;
    background: transparent;
    box-shadow: none;
    color: #000;
  }
  .header .item-group > span:hover .h-item-ttip {
    display: block;
    animation: fadein 0.3s 0.5s forwards;
  }
  .header .item-group span > p:not(:first-child) {
    display: none;
  }
  .header .item-group > span:hover {
    transform: none;
    background: none;
  }
  .header .item-group > span:hover::after {
    background: rgba(255, 255, 255, 0.5);
    visibility: visible;
    border-color: #fff;
    animation: borderanim 0.2s;
  }
  .home .category {
    position: fixed;
    bottom: 6rem;
    right: -4%;
    /* margin: 5% 0; */
    /* margin-top: 15%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* top: 10px; */
    z-index: 32;
    padding: 0;
  }

  .home .products .c-category {
    justify-content: center;
    /* align-items: center; */
  }
  .home .products .c-category p {
    justify-content: center;
  }
  .home .category .cat-cont {
    background: #fff;
    border-radius: 0;
    position: absolute;
    bottom: 100%;
    right: 20%;
    top: auto;
    display: none;
    padding: 0;
    animation: popup 0.2s;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: visible;
    min-width: 70vw;
    max-height: 60vh;
    overflow-y: auto;
  }

  .home .category .cat-item {
    font-size: 0.8rem;
    border: none;
    border-radius: 3px;
    padding: 15px 1.5rem;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin: 3px 0;
    box-shadow: none;
  }
  /* .home .category .cat-item >:first-child {
    display: none;
  } */
  .home .category .cat-menu {
    display: none;
  }

  .home .products .c-category p {
    font-size: 12px;
  }

  .select-outlet ul li {
    padding: 0;
    margin: 10px 0;
  }

  .home .products {
    padding: 0;
    margin: 0;
  }

  .home .products .product-items {
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* grid-template-columns: 1fr 1fr; */
  }

  .header {
    box-shadow: none;
  }
  .header .title {
    font-size: 20px;
  }
  .header .left {
    flex-direction: column;
  }
  .header .burgerMenu {
    transform: scale(0.7);
    margin: 0px 15px 0px 5px;
  }

  .root .root-header .title img {
    position: relative;
    width: 10vh;
    height: 10vh;
    z-index: 20;
    object-fit: contain;
  }
  .payment {
    display: flex;
    flex-direction: column;
  }
  .payment .ch-btn {
    /* flex-direction: column; */
  }
  .payment .pay-form form {
    position: relative;
  }
  .payment input {
    width: 75vw;
  }
  .payment .pay-form {
    padding-bottom: 20px;
  }
  .payment .pay-form .inp-cont label {
    top: 5px;
  }
  .payment .ch-bill {
    margin-top: 15%;
    padding: 0 5%;
  }
  .payment .p-head {
    top: 2%;
    left: 3%;
  }
  .p-f-inner-cont {
    position: relative;
    height: auto;
  }
  .header .title {
    top: 0;
    left: 0;
  }
  .header .title img {
    margin: 10px;
    width: 45px;
  }
  .header .left .title p {
    margin: 0;
    box-shadow: none;
  }
  .header .left .title span {
    width: 50%;
    background-color: transparent;
    white-space: pre-line;
    padding: 0;
    /* font-size: 0.5rem; */
  }
  .header .left .title span svg {
    display: none;
  }
  .header .item-group {
    margin-right: 10px;
  }

  .header .left .title p {
    position: relative;
    font-size: 10px;
    padding: 5px 4px;
  }
  .header .left p:after {
    padding: 0 2px;
    top: -5px;
    font-size: 8px;
  }
  .root .new-feature {
    left: 10px;
    right: 10px;
    width: auto;
  }
  .root .new-feature h2,
  .root .new-feature p {
    display: none;
  }
  .root .new-feature {
    text-align: center;
  }
  .root .new-feature img {
    width: 70%;
    height: 100px;
  }
}

/* ul {
  margin: 0;
  padding: 0;
} */




.logutbox{
  display: flex;
  margin:1rem;
}
.adj-btn{
  margin: 0 !important;
}
@media (max-width:767px) {
  .footer-top > div {
    display: block !important;
  }
  .footer-top .f-inp > div{
    margin: 1rem !important;
    padding: .8rem;
  }
  .footer-top .f-inp input{
    margin-right: 0rem;
  }
}
@media (max-width:991px) {
  .logutbox{
    display: block;
  }
  .login{
    width: 94%;
    margin: 0 auto;
    display: block;
    height: auto;
  }
  .l-head-adj{
    margin-top: 0rem  !important;
  }
  .l-btn{
    margin-bottom: 0rem  !important;
  }
  .l-input {
    margin-top: 0.8rem !important;
  }
  /* .product-items {
    display: block !important;
  }
  .product-card{
    margin: 4rem auto;
  }
  .p-items {
    width: 97%;
  } */
}
#myUL{
  padding: 0;
}