.pr-details {
  // animation: pop-up 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  min-height: 100vh;
  padding-top: 2rem;
}

.details-panel {
  display: grid;
  max-width: 400px;
  width: 90%;
  grid-template-columns: 1fr;
  margin: 1rem auto;
  column-gap: 7rem;
  .divider {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin: 1.5rem 0;
  }
  &-left {
    .back-btn {
      margin-bottom: 1rem;
      & > div {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 500;
        svg {
          font-size: 1rem;
          margin-right: 5px;
        }
      }
    }
    img {
      display: block;
      width: 100%;
      object-fit: cover;
      transition: 0.2s;
      max-height: 20rem;
      //   height: 15rem;
    }
    .primary-img {
      position: relative;
      cursor: zoom-in;
      margin-bottom: 1rem;
      min-height: 15rem;
      background-color: #eee;
      overflow: hidden;
      img {
        object-fit: cover;
        height: 100%;
      }
      .img-arrows {
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        padding: 5px;
        transition: 0.5s;
        svg {
          cursor: pointer;
          pointer-events: all;
          padding: 0.5rem;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
        }
      }
      &:hover {
        .img-arrows {
          opacity: 1;
        }
      }
    }
    .mul-img {
      img {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          filter: brightness(120%);
        }
      }
    }
  }
  &-right {
    // background-color: #f1f1f1;
    margin: 2rem 0;
    p {
      margin: 0;
    }
    .det-info {
      .p-name {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        font-weight: 500;
        font-size: 1.7rem;
      }
      .p-allergens {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1rem;
        margin-top: 5px;
        & > div {
          position: relative;
          margin-right: 5px;
          svg {
            width: 1.8rem;
            height: 1.8rem;
          }
          @include ttip-hover() {
            background-color: #222;
            color: #fff;
            transform: translate(0, -2rem);
          }
          .ttip {
            right: 0;
            left: auto;
            transform: none;
          }
        }
      }
      .p-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        margin: 5px 0 1rem 0;
        & > span {
          display: inline-block;
          font-weight: normal;
          font-size: 0.8rem;
          color: $color-accent;
          box-shadow: 0 0 0 1px $color-accent;
          font-weight: bold;
          padding: 5px 0.7rem;
          border-radius: 5px;
        }
      }
      .p-rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        svg {
          font-size: 1.3rem;
        }
        .p-stars {
          position: relative;
          span {
            position: absolute;
            height: 100%;
            background-color: #fff;
            right: 0;
          }
          .s-o {
            position: absolute;
            top: 0;
            z-index: 2;
            svg {
              color: #ffa50075;
            }
          }
        }
        b {
          white-space: nowrap;
          font-size: 0.7rem;
          margin-left: 0.7rem;
          color: #aaa;
          border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
          padding-left: 5px;
        }
        & > div {
          display: inline-flex;
          align-items: center;
          font-weight: 500;
          svg {
            margin-right: 0.5rem;
          }
          &:first-child {
            svg {
              color: orange;
              margin-right: 2px;
            }
          }
        }
        .p-ac {
          & > div {
            position: relative;
            display: inline-flex;

            @include ttip-hover("top", 2rem) {
              background-color: #222;
              color: #fff;
            }
            .ttip {
              right: auto;
              left: auto;
            }
          }
          svg {
            font-size: 1.6rem;
            &:hover {
              cursor: pointer;
              color: #555;
            }
            &:active {
              transform: scale(0.95);
            }
          }
          .i-o {
            position: absolute;
            font-size: 0.8rem;
            border-radius: 50%;
            top: -2px;
            right: -2px;
            background-color: #fff;
          }
        }
      }
      .p-desc {
        color: #555;
        font-size: 0.9rem;
        white-space: pre-line;
      }
      .p-table {
        display: grid;
        grid-template-columns: auto 1fr;
        font-size: 0.8rem;
        gap: 1.5rem;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: space-between;
        font-weight: 500;
        text-transform: uppercase;
        &:not(span) {
          color: $color-accent;
          text-align: end;
        }
        span {
          text-align: start;
          color: #000;
        }
        i {
          color: #ddd;
        }
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      .p-pricing {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // margin: 2rem 0;
        .counter-cont {
          width: auto;
          margin-right: 2rem;
          .counter {
            height: 50px;
            margin: 0;
            box-shadow: none;
          }
        }
        & > p {
          font-weight: bold;
          font-size: 1.3rem;
          color: $color-accent;
          margin: 0;
          span {
            color: #aaa;
            font-weight: normal;
            font-size: 1rem;
            margin-right: 10px;
            text-decoration: line-through;
          }
        }
      }
      .p-btns {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // column-gap: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        & > div {
          display: inline-flex;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 0 0 1px $color-accent;
          color: $color-accent;
          padding: 0.9rem 0;
          border-radius: 5px;
          font-weight: bold;
          flex: 1 0;
          transition: 0.2s;
          svg {
            margin-right: 0.7rem;
            font-size: 1.2rem;
          }
          &:nth-child(n + 2) {
            margin-left: 1rem;
          }
          &:first-child {
            background-color: $color-accent;
            color: #fff;
          }
          &:hover {
            filter: brightness(110%);
          }
        }
      }
    }
  }
  @include respond-to("md") {
    grid-template-columns: 5fr 4fr;
    max-width: 60rem;
    &-left {
      .primary-img {
        .img-arrows {
          opacity: 0;
        }
      }
      .back-btn {
        margin-bottom: 1rem;
        & > div {
          font-size: 1rem;
          font-weight: 500;
          svg {
            font-size: 1.2rem;
            margin-right: 5px;
          }
        }
      }
    }
    &-right {
      margin: 0;
    }
  }
}

.rec-prod {
  margin: 4rem auto;
  margin-top: 7rem;
  max-width: 400px;
  width: 90%;
  > p {
    position: relative;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      top: 120%;
      left: 0;
      width: 5rem;
      border-bottom: 3px solid $color-accent;
    }
  }
  ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 1rem;

    .prod-item {
      cursor: pointer;
      min-width: 10rem;
      max-width: 10rem;
      margin-left: 1.5rem;
      img {
        width: 100%;
        height: 12rem;
        object-fit: cover;
      }
      &.no-image {
        img {
          object-fit: contain;
          padding: 3rem;
        }
      }
      &:first-child {
        margin-left: 3px;
      }
      &-top {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        margin-bottom: 10px;
        background-color: #fff;
        img {
          max-height: 8rem;
        }
        .r-c-rate {
          position: absolute;
          top: 5px;
          left: -3px;
          display: inline-flex;
          align-items: center;
          background-color: #ffffffe9;
          padding: 5px 10px;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          font-weight: bold;
          z-index: 5;
          svg {
            color: orange;
            margin-right: 0.5rem;
          }
        }
      }
      &-det {
        padding: 0.5rem;
        p {
          font-weight: 500;
          margin-bottom: 1rem;
        }
        > span {
          // font-size: 0.9rem;
          font-weight: bold;
          color: $color-accent;
        }
      }
      &:hover {
        background-color: #fafafa;
        box-shadow: 0 2px 3px rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
  @include respond-to("md") {
    max-width: 60rem;
    ul {
      .prod-item {
        min-width: 12rem;
        max-width: 15rem;
        &-top {
          img {
            max-height: none;
          }
        }
      }
    }
  }
}

.distance {
  display: inline-block;
  font-weight: normal;
  font-size: 0.8rem;
  color: $color-accent;
  box-shadow: 0 0 0 1px $color-accent;
  font-weight: bold;
  padding: 5px 0.7rem;
  border-radius: 5px;
  margin-left: 30px;
}
// .distance:hover{
//   display: inline-block;
//   font-weight: normal;
//   font-size: 0.8rem;
//   color: white;
//   box-shadow: 0 0 0 1px white;
//   font-weight: bold;
//   padding: 5px 0.7rem;
//   border-radius: 5px;
//   margin-left: 30px;

// }
// .distance:focus{
//   display: inline-block;
//   font-weight: normal;
//   font-size: 0.8rem;
//   color: white;
//   box-shadow: 0 0 0 1px white;
//   font-weight: bold;
//   padding: 5px 0.7rem;
//   border-radius: 5px;
//   margin-left: 30px;

// }
