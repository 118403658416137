$breakpoints: (
  xs: 320px,
  sm: 740px,
  md: 980px,
  lg: 1300px,
);

@mixin respond-to($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);
  @if $value != null {
    @media (min-width: $value) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Please make sure it is defined in `$breakpoints` map.";
  }
}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin hovBox() {
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 5px 1rem rgba($color: #000000, $alpha: 0.05);
    @content;
  }
}
@mixin scrollBar-sm($width: 5px) {
  & {
    scrollbar-width: thin;
    scrollbar-color: #999 #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
  }
  &::-webkit-scrollbar {
    width: $width;
    @content;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
  }
}

@mixin ttip-hover($direction: "top", $offset: 2rem) {
  .ttip {
    position: absolute;
    white-space: nowrap;
    font: bold 0.7rem "Montserrat";
    background-color: #fff;
    border-radius: 2px;
    color: #222;
    padding: 0.5rem 10px;
    #{$direction}: -10px;
    left: 50%;
    transition: 0.2s;
    clip-path: circle(0% at 50% 100%);
    opacity: 0;
    box-shadow: 0 5px 1rem rgba($color: #000000, $alpha: 0.1);
    transform: translate(-50%, 0);
  }
  &:hover {
    .ttip {
      opacity: 1;
      clip-path: circle(100% at 50% 50%);
      transform: translate(-50%, $offset);
      @if $direction== "top" {
        transform: translate(-50%, -$offset);
      }
      @content;
    }
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}
