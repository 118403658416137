@keyframes slideup {
  0% {
    bottom: -100%;
  }
  /* 50%{transform: translateY(0)} */
  100% {
    bottom: 0;
  }
}
@keyframes hov {
  0% {
    transform: translateY(10px);
  }
  /* 50%{transform: translateY(0)} */
  100% {
    transform: translateY(0);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(350deg);
  }
}
@keyframes roam {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes popout {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes drop {
  from {
    opacity: 0;
    transform: translateY(-5px) scale(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@keyframes popup {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes borderanim {
  0% {
    border-width: 0px;
    padding: 0px;
  }
  50% {
    border-width: 20px;
    padding: 0px;
  }
  100% {
    border-width: 1px;
  }
}
@keyframes upNdown {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(2px);
  }
}
@keyframes scale {
  0% {
    box-shadow: 0 0 5px 0 #76ff03;
  }
  50% {
    box-shadow: 0 0 5px 4px #76ff03;
  }
  100% {
    box-shadow: 0 0 5px 0 #76ff03;
  }
}
@keyframes flicker {
  0% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2px);
  }
}
@keyframes bounce {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(5px);
  }
  40% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes smoothSlide {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  25% {
    transform: translateY(-2px);
    opacity: 0;
  }
  50% {
    transform: translateY(10px);
    opacity: 0;
  }
  75% {
    transform: translateY(-2px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}
@keyframes dropfromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes bloom {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes openSmooth {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
@keyframes reflectEffect {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes bounceUp {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  70% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes indicator {
  0% {
    transform: translateY(5px);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}
@keyframes clipAnim{
  0%{
    clip-path: circle(0% at 50% 50%);
  }
  100%{
    clip-path: circle(80% at 50% 50%);

  }
}
.toast-enter {
  animation-name: dropfromTop;
}
.toast-exit {
  animation-name: fadeOut;
}
.toast-progress {
  height: 0;
}
