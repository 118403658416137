// @font-face {
//   font-family: 'Montserrat';
//   src: url('../fonts/MontserratSans-VariableFont_wght.ttf') format('truetype');
// }
.center-content {
  display: grid;
  place-items: center;
}
ul {
  list-style-type: none;
}
input {
  border: none;
}

.spread {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.acc-hov-after {
  &:after {
    content: '';
    transition: 0.2s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    background-color: $color-accent;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    box-shadow: 0 10px 1rem rgba($color: #000000, $alpha: 0.1);
    background-color: $color-accent;
    &::after {
      right: 0;
    }
  }
  &:active {
    transform: scale(0.9);
  }
}

.no-data {
  width: 100%;
  text-align: center;
  // box-sizing: border-box;
  padding: 5rem 0;
  & > svg {
    opacity: 0.1;
    font-size: 3rem;
  }
  p {
    margin: 1rem 0;
    font-size: 1.3rem;
    font-weight: bold;
    color: #ccc;
  }
  @include respond-to('md') {
    & > svg {
      font-size: 5rem;
    }
    p {
      margin: 1rem 0;
      font-size: 1.5rem;
    }
  }
}
.a-loader {
  position: relative;
  display: grid;
  place-items: center;
  z-index: 10;
  & > span {
    position: absolute;
    font-weight: bold;
    font-size: 1.5rem;
    color: $color-accent;
    font-family: 'Montserrat';
    margin-top: 3px;
  }
  svg {
    width: 5rem;
    height: 5rem;
    animation: rotate 2s linear infinite;
    stroke: $color-accent;
    stroke-width: 3px;
    // stroke-dashoffset: -10;
    stroke-dasharray: 10%;
    fill: transparent;
    stroke-linecap: round;
  }
}
.gl-loader {
  position: fixed;
  @extend .spread;
  @extend .center-content;
  z-index: 500;
  background-color: #fff;
  & > div {
    position: relative;
    @extend .center-content;
    img {
      // filter: invert(1);
      width: 8rem;
    }
    p {
      font: bold 2rem 'Montserrat';
      color: $color-accent;
      position: absolute;
      margin-top: 5px;
      padding: 0;
    }
    svg {
      animation: dashAnim 2s linear infinite, rotate 3s linear infinite;
      width: 3rem;
      height: 3rem;
      fill: none;
      stroke-width: 4px;
      stroke: $color-accent;
      stroke-linecap: round;
    }
  }
}

.item-ttip {
  position: absolute;
  top: 110%;
  /* bottom: -30px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  background: #000000da;
  color: #fff;
  border-radius: 3px;
  opacity: 0;
  font-style: normal;
  display: none;
  /* z-index: 10; */
  white-space: nowrap;
}
.cart-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
  animation: fadein 0.4s;
}

.diag-wrapper {
  animation: fadeInAmin 0.2s;
  display: grid;
  place-items: center;
  z-index: 400;
  position: fixed;
  @extend .spread;
  background-color: rgba(0, 0, 0, 0.4);
  .diag-body {
    position: relative;
    min-width: 30vh;
    min-height: 30vh;
    animation: pop-up 0.2s;
    z-index: 10;
    .d-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  @include respond-to('md') {
    .diag-body {
      .d-close {
        left: auto;
        right: -3rem;
        top: 0;
        z-index: 100;
        background-color: #fff;
        padding: 5px;
        border-radius: 50%;
        font-size: 1.4rem;
        cursor: pointer;
        // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}
.fade-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff56;
  z-index: 100;
}

.alert-box {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 500;
}

.ch-success-overlay {
  position: absolute;
  width: 100%;
  height: 0;
  background-color: #fff;
  top: 0;
  left: 0;
  box-shadow: 0 5px 1rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    opacity: 0;
    text-align: center;
    > svg {
      font-size: 3rem;
      color: #00c853;
      box-shadow: 0 5px 1rem rgba($color: #000000, $alpha: 0.1);
      border-radius: 50%;
      padding: 10px;
    }
    > p {
      margin: 1rem 0;
      font-size: 2rem;
      font-weight: 500;
      // color: green;
    }
    span {
      display: block;
      max-width: 30rem;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .o-rate {
      position: relative;
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      padding: 1rem;
      margin-top: 2rem;
      .r-o-fr {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: popup 0.5s;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        font-weight: 500;
        svg {
          margin-right: 0.5rem;
        }
      }
      p {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }
      svg {
        cursor: pointer;
        font-size: 2rem;
        color: orange;
        transition: 0.2s;
        &:hover {
          background-color: orange;
          color: #fff;
        }
      }
    }
  }
}
