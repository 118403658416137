.checkout-page {
  animation: fadein 0.2s;
  display: grid;
  grid-template-columns: 1fr;
  * {
    box-sizing: border-box;
    font-family: "Montserrat";
  }
  .c-logo {
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    & > img {
      display: block;
      // width: 4rem;
      margin: 0;
      height: 4rem;
      filter: invert(0.15);
    }
  }
  button {
    cursor: pointer;
    transition: 0.2s;
    outline: none;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    font-size: 1rem;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
    &:hover {
      filter: brightness(105%);
    }
    &:active {
      transform: scale(0.95);
      box-shadow: none;
    }
  }
  .box-title {
    margin: 2rem 0 1rem 0;
    // color: #777;
    font: 500 1rem "Montserrat";
  }
  .log-box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem 1.5rem;
    // border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 0 1px $color-accent;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 6rem;
    //   height: 6rem;
    //   background-color: #ffffff23;
    //   border-radius: 50%;
    //   top: -2rem;
    //   left: -3rem;
    //   box-shadow: 32rem 0 0 1.5rem #ffffff23,
    //     3rem 3rem 0 rgba($color: #000, $alpha: 0.05);
    //   z-index: 1;
    // }
    p {
      display: inline-flex;
      font-weight: bold;
      color: $color-accent;
      z-index: 3;
      svg {
        margin-right: 1rem;
        font-size: 1.2rem;
      }
    }
    button {
      color: #fff;
      background-color: $color-accent;
      padding: 0.5rem 1.5rem;
      font-size: 1rem;
      font-weight: bold;
      z-index: 3;
    }
  }
  @include respond-to("md") {
    grid-template-columns: 1fr 500px;
    .c-logo {
      top: 1rem;
      left: 2rem;
      & > img {
        // width: 6rem;
        filter: invert(0.15);
        margin: 0;
        height: 3.5rem;
      }
    }
  }
}
.steps-wrapper {
  // min-width: 550px;
  @include respond-to("md") {
    margin: 0 50px;
  }
  @include respond-to("lg") {
    margin: 0 auto;
  }
  margin: 0 auto;
  width: 90%;
  padding-bottom: 5rem;
  .panel-cont {
    min-height: 50vh;
  }
  .bottom-btn-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    & > button {
      display: inline-flex;
      align-items: center;
      border: none;
      padding: 10px 1rem;
      font-size: 1rem;
      border-radius: 5px;
    }
    .btn-cnt {
      background-color: $color-accent;
      color: #fff;
      font-weight: bold;
      svg {
        margin-left: 1rem;
      }
    }
    .btn-prev {
      background: none;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.4);
      svg {
        margin-right: 1rem;
      }
    }
  }
  @include respond-to("md") {
    width: 550px;
  }
}
.stepper-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 2rem 0;
  font-weight: 500;
  font-size: 0.8rem;
  .step {
    display: inline-flex;
    align-items: center;
    margin: 0 10px;
    & > span,
    p {
      display: inline-block;
      margin: 0;
    }
    p {
      margin-right: 10px;
    }
    .step-num {
      display: grid;
      place-items: center;
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      font-weight: bold;
      box-shadow: 0 0 0 2px #000;
      transition: 0.2s;
    }
  }
  .selected {
    font-weight: bold;
    .step-num {
      background-color: #000;
      color: #fff;
    }
  }
  @include respond-to("md") {
    font-size: 1rem;
    .step {
      margin: 0 2rem;
    }
  }
}
.billing-form {
  max-width: 700px;
  form {
    display: grid;
    row-gap: 1rem;
  }
  .b-inp {
    position: relative;
    label {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-55%);
      transition: 0.1s;
      pointer-events: none;
      font-size: 0.9rem;
    }
    select {
      width: 100%;
      height: 100%;
      padding: 10px;
      padding-top: 1.3rem;
      background-color: transparent;
      outline: none;
      font-weight: 500;
      font-size: 1rem;
      border-color: #d2d2d2;
      // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
      // border-radius: 2px;
      &::placeholder {
        color: transparent;
      }
    }
    input {
      width: 100%;
      height: 100%;
      padding: 10px;
      padding-top: 1.3rem;
      background-color: transparent;
      outline: none;
      font-weight: 500;
      font-size: 1rem;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
      // border-radius: 2px;
      &::placeholder {
        color: transparent;
      }
      &:focus,
      &:not(:placeholder-shown) {
        box-shadow: 0 0 0 1px $color-accent;
        & ~ label {
          font-size: 0.7rem;
          font-weight: 500;
          color: $color-accent;
          top: 0.8rem;
          left: 10px;
        }
      }
      &:focus {
        box-shadow: 0 0 0 1px #000;
      }
    }
  }
  .inp-grp {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  @include respond-to("md") {
    .inp-grp {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      column-gap: 1rem;
    }
  }
}
.o-payment {
  &-methods {
    > p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  ul {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    li {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      column-gap: 2rem;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      min-height: 5rem;
      padding: 1rem 2rem;
      border-radius: 5px;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      svg {
        box-sizing: content-box;
        padding: 5px;
      }
      .i-ch {
        animation: fadein 0.2s;
        background-color: green;
        color: #fff;
        border-radius: 50%;
      }
      img {
        height: 10px;
        width: 5rem;
        object-fit: cover;
      }
      span {
        font-size: 0.8rem;
      }
      p {
        margin: 0;
        font-weight: bold;
      }

      &:hover:not(.selected) {
        background-color: #fafafa;
      }
    }
    .selected {
      box-shadow: 0 0 0 1px green;
    }
    .op-disable {
      opacity: 0.5;
      pointer-events: none;
      box-shadow: none;
    }
  }
  &-banner {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1.5rem;
    padding: 1.5rem;
    box-shadow: 0 0 0 1px $color-accent;
    // border-top: 1px solid $color-accent;
    margin-top: 2rem;
    // background-color: $color-accent;
    color: $color-accent;
    p {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 5px;
    }
    span {
      font-size: 0.8rem;
      font-weight: 500;
    }
    svg {
      font-size: 2rem;
      // color: $color-accent;
      box-sizing: content-box;
      border-radius: 50%;
    }
  }
  // @include respond-to('md') {
  //   &-methods {
  //     border: 1px solid rgba($color: #000000, $alpha: 0.2);
  //     padding: 2rem;
  //   }
  // }
}
.c-prod {
  padding: 1rem;
  .ch-main {
    display: grid;
    grid-template-columns: 6rem 1fr auto;
    column-gap: 1rem;
    .p-price {
      font-size: 0.8rem;
      margin: 5px 0;
      font-weight: 500;
    }
    .counter-cont {
      .counter {
        height: 50px;
        border-radius: 0;
        // margin-top: 5px;
        & > {
          min-width: auto;
        }
      }
    }
    & > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 100%;
        height: 4rem;
        background-color: #fafafa;
        object-fit: cover;
      }
      .p-name {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .p-con {
        // font-family: 'Cormorant Garamond';
        font-size: 0.7rem;
        margin-bottom: 10px;
      }
      .ch-custom {
        & > span {
          display: inline-block;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
          font-size: 0.6rem;
          margin-right: 5px;
          padding: 1px 3px;
          border-radius: 1rem;
        }
      }
    }
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.02);
  }
}
.cart-panel {
  display: none;
  border: 1px solid #ddd;
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.05);
  margin: 0 3rem;
  margin-top: 2rem;
  &-head {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    padding: 1rem;
    margin: 0;
    // box-shadow: 0 5px 2px rgba($color: #000000, $alpha: 0.05);
    z-index: 10;
    .h-r {
      position: absolute;
      right: 1rem;
      top: 30%;
      span {
        font-size: 0.8rem;
      }
    }
    svg {
      display: inline-block;
      margin-right: 1rem;
    }
  }
  .ch-cart {
    max-height: 50vh;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
    // border-right: none;
    // border-left: none;
    @include scrollBar-sm(3px);
    background-color: #fafafa;
    // box-shadow: inset 0 0 10px rgba($color: #000, $alpha: 0.05);
  }
  .coupon-cont {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5rem;
      font-size: 1.3rem;
      color: $color-accent;
    }
    input,
    select {
      flex: 1;
      padding: 0.8rem;
      font-size: 1rem;
      outline: none;
      text-align: center;
      margin-right: 1rem;
      padding-left: 3rem;
      // background-color: rgba($color: #000000, $alpha: 0.02);
      text-transform: uppercase;
      font-weight: 500;
    }
    button {
      background-color: $color-accent;
      color: #fff;
      border-radius: 5px;
      padding: 0.6rem 0.8rem;
      border: none;
      // box-shadow: 0 0 0 1px #fff;
    }
  }
  .bill-cont {
    padding-top: 1rem;
    .bill {
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 1rem;
        span {
          font-size: 0.9rem;
        }
        &:last-child {
          padding: 1rem;
          font-weight: 500;
          // margin-top: 10px;
          // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
          // font-weight: bold;
        }
      }
      .d-p {
        color: $color-accent;
      }
    }
  }
  @include respond-to("md") {
    display: block;
  }
}
.m-cart {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
  background-color: #fff;
  z-index: 100;
  .m-mask {
    animation: fadein 0.2s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000a9;
    z-index: -1;
  }
  &-head {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: $color-accent;
    // margin: 10px;
    color: #fff;
    border-top: 1px solid rgba($color: #000, $alpha: 0.2);
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
    svg {
      transition: 0.2s;
    }
    & > div {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 1rem;
      align-items: center;
    }
  }
  &-body {
    overflow: hidden;
    background-color: #fff;
    .m-c-list {
      // height: 85vh;
      // height: auto;
      max-height: 40vh;
      overflow-y: auto;
      scroll-behavior: smooth;
      ul {
        padding: 0;
        margin: 1rem 0;
      }
    }
  }
  @include respond-to("md") {
    display: none;
  }
}

.payment .sa-cont {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 0.1s;
  font-family: "Montserrat";
}
.payment .sa-cont .sa-main {
  background-color: #fff;
  min-width: 40%;
  min-height: 50%;
  max-width: 60vw;
  border-radius: 5px;
  box-shadow: 0 5px 10px #000;
  display: flex;
  flex-direction: column;
  animation: popup 0.2s;
}
.payment .sa-cont .sa-main .sa-header {
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.payment .sa-cont .sa-main .sa-header .ad-head-close {
  position: absolute;
  right: 40px;
  top: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}
.payment .sa-cont .sa-main .sa-header .ad-head-close:hover {
  background-color: #eee;
}
.sa-main .sa-header *:first-child {
  margin-right: 20px;
  padding: 10px;
  background-color: #eee;
  border-radius: 50px;
}
.sa-main .sa-header span {
  font-size: 1.2rem;
}
.sa-main .sa-body {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.sa-main .sa-body .ad-err {
  height: 100%;
  align-self: center;
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sa-main .sa-body .ad-err span {
  margin: 10px;
  color: rgb(148, 5, 5);
}
.sa-main .sa-body > button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  box-shadow: 0 3px 10px rgba($color: #000, $alpha: 0.2);
  background-color: $color-accent;
  color: #fff;
  border-radius: 5px;
  font-family: "Montserrat";
  font-size: 1rem;
}
.sa-main .sa-body .sa-ad-cont {
  display: grid;
  grid-template-rows: auto;
  @include respond-to("md") {
    grid-template-columns: 1fr 1fr;
  }
  @include respond-to("sm") {
    grid-template-columns: 1fr 1fr;
  }

  // padding: 20px;
}
.sa-main .sa-body .sa-ad-cont .sa-ad-item {
  padding: 20px 15px 0 15px;
  border: 1px solid rgba($color: #000, $alpha: 0.2);
  border-radius: 5px;
  margin: 10px 5px;
  cursor: pointer;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
}
.sa-main .sa-body .sa-ad-cont .sa-ad-item:hover:not(.sa-ad-item-selected) {
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04); */
  background-color: $color-accent;
  color: white;
}

.sa-main .sa-body .sa-ad-cont .sa-ad-item-selected {
  background-color: $color-accent;
  border-color: $color-accent;
  box-shadow: 0 5px 10px $color-accent;
  color: white;
}

.sa-ad-cont .sa-ad-item .ad-item-addr {
  /* margin-top: 10px; */
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize;
}
.sa-ad-cont .sa-ad-item .ad-item-addr-desc {
  font-size: 0.8rem;
  margin-top: 5px;
  text-transform: capitalize;
}
.sa-main .sa-body .sa-ad-cont .sa-ad-item .ad-item-det {
  animation: fadein 0.2s;
}
.sa-ad-cont .sa-ad-item *:last-child {
  align-self: flex-end;
  margin: 10px 0;
}
.m-cart-panel {
  border: 1px solid #ddd;
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.05);
  margin: 0 3rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  .coupon-cont {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1rem;
      font-size: 1.3rem;
      color: $color-accent;
    }
    input,
    select {
      // flex: 1;
      padding: 0.8rem;
      font-size: 0.8rem;
      outline: none;
      text-align: center;
      margin-right: -1rem;
      padding-left: 1.5rem;
      // background-color: rgba($color: #000000, $alpha: 0.02);
      text-transform: uppercase;
      font-weight: 500;
    }
    button {
      background-color: $color-accent;
      color: #fff;
      border-radius: 5px;
      padding: 0.6rem;
      border: none;
      margin-right: 1rem;
      position: absolute;
      right: 0;
      // box-shadow: 0 0 0 1px #fff;
    }
  }
  .bill-cont {
    padding-top: 1rem;
    .bill {
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 1rem;
        span {
          font-size: 0.9rem;
        }
        &:last-child {
          padding: 1rem;
          font-weight: 500;
          // margin-top: 10px;
          // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
          // font-weight: bold;
        }
      }
      .d-p {
        color: $color-accent;
      }
    }
  }
}


.checkout-drawer{
  background-color: #fff;
  position: sticky;
  // width:100%;
  z-index:100;
  .coupon-cont {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    // border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5rem;
      font-size: 1.3rem;
      color: $color-accent;
    }
    input,
    select {
      flex: 1;
      padding: 0.8rem;
      font-size: 1rem;
      outline: none;
      text-align: center;
      margin-right: 1rem;
      padding-left: 3rem;
      // background-color: rgba($color: #000000, $alpha: 0.02);
      text-transform: uppercase;
      font-weight: 500;
    }
    button {
      background-color: $color-accent;
      color: #fff;
      border-radius: 5px;
      padding: 0.6rem 0.8rem;
      border: none;
      // box-shadow: 0 0 0 1px #fff;
    }
  }
}
