.account-page {
  animation: fadein 0.2s;
  display: grid;
  grid-template-columns: 1fr;
  margin: 3% 4%;
  * {
    box-sizing: border-box;
    font-family: "Montserrat";
  }
  .c-logo {
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    & > img {
      display: block;
      // width: 4rem;
      margin: 0;
      height: 4rem;
      filter: invert(0.15);
    }
  }
  .go-back {
    border-radius: 50%;
    padding: 10px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $color-accent;
  }
  button {
    cursor: pointer;
    transition: 0.2s;
    outline: none;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    font-size: 1rem;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
    &:hover {
      filter: brightness(105%);
    }
    &:active {
      transform: scale(0.95);
      box-shadow: none;
    }
  }
  .box-title {
    margin: 2rem 0 1rem 0;
    // color: #777;
    font: 500 1rem "Montserrat";
  }
  .log-box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem 1.5rem;
    // border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 0 1px $color-accent;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 6rem;
    //   height: 6rem;
    //   background-color: #ffffff23;
    //   border-radius: 50%;
    //   top: -2rem;
    //   left: -3rem;
    //   box-shadow: 32rem 0 0 1.5rem #ffffff23,
    //     3rem 3rem 0 rgba($color: #000, $alpha: 0.05);
    //   z-index: 1;
    // }
    p {
      display: inline-flex;
      font-weight: bold;
      color: $color-accent;
      z-index: 3;
      svg {
        margin-right: 1rem;
        font-size: 1.2rem;
      }
    }
    button {
      color: #fff;
      background-color: $color-accent;
      padding: 0.5rem 1.5rem;
      font-size: 1rem;
      font-weight: bold;
      z-index: 3;
    }
  }
  @include respond-to("md") {
    grid-template-columns: 1fr auto;
    margin: 0 18%;
    .c-logo {
      top: 1rem;
      left: 1rem;
      & > img {
        // width: 6rem;
        margin: 0;
        height: 3.5rem;
        filter: invert(0.15);
      }
    }
  }
}

.account-section {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  .category {
    // display: none;
    justify-content: center;
    align-items: flex-start;

    .cat-cont {
      position: sticky;
      top: 6.5rem;
      margin: 0;
      padding: 0;
      padding-right: 0;
      // width: 90%;
      margin-top: 2rem;
      box-shadow: 0 0 0 1px rgba($color: #000, $alpha: 0.1);
      &-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.7rem 1rem;
        p {
          display: inline-flex;
          align-items: center;
          color: $color-accent;
          svg {
            margin-right: 0.5rem;
          }
        }
      }
      ul {
        @include scrollBar-sm();
        margin: 0;
        padding: 0;
        background-color: #fafafa;
        // border-top: 1px solid rgba($color: #000, $alpha: 0.1);
        overflow-y: auto;
        max-height: 30rem;
      }
      li {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 1rem;
        cursor: pointer;
        font-weight: 500;
        font-size: 0.9rem;
        z-index: 5;
        & > span {
          font-weight: normal;
        }
        &:hover:not(.selected) {
          background-color: #f1f1f1;
        }
        &::after {
          content: "";
          position: absolute;
          height: 100%;
          right: 0;
          top: 0;
          width: 0%;
          background-color: $color-accent;
          z-index: -1;
          transition: 0.2s;
          box-shadow: 0 2px 5px rgba($color: #000, $alpha: 0.1);
        }
      }
      .selected {
        color: #fff;
        font-weight: bold;
        & > span {
          font-weight: 500;
        }
        &::after {
          width: 100%;
        }
      }
    }
  }
  .products {
    .top-search-mobile {
      position: relative;
      padding: 1rem 1.5rem;
      svg {
        position: absolute;
        top: 50%;
        left: 2.3rem;
        font-size: 1.3rem;
        transform: translateY(-50%);
      }
      input {
        width: 100%;
        box-sizing: border-box;
        background-color: #eee;
        // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.3);
        border-radius: 10px;
        font-size: 1.2rem;
        padding: 1rem 1rem 1rem 3rem;
        outline: none;
      }
    }
    .menu-search {
      position: sticky;
      top: 4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      z-index: 10;
      background-color: #ffffffea;
    }
    .p-items {
      padding-top: 2rem;
      .sk-ld {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-items: center;
        justify-content: center;
      }
      .product-items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 4rem 0;
        margin: 2rem 0;
        margin-bottom: 6rem;
        justify-items: center;
      }
      .p-cat {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: 500;
        // font-family: 'Cormorant Garamond';
        margin-bottom: 4rem;
        box-sizing: border-box;
        svg {
          display: none;
          margin-right: 1rem;
          font-size: 1rem;
          padding: 0.5rem;
          box-shadow: 0 0 0 1px rgba($color: #000, $alpha: 0.1);
          border-radius: 50%;
        }
      }
      .c-cat {
        border-left: 5px solid rgb(255, 214, 0);
        margin: 1rem 2rem;
        padding: 0 10px;
      }
    }
  }

  .top-menu-bar {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 4.5rem;
    z-index: 10;
    background-color: #fff;
    padding: 0.5rem 0;
    box-shadow: 0 0 0 2px #fff;
    padding-right: 2rem;
    // border-bottom: 1px solid rgba($color: $color-accent, $alpha: 0.1);
    .u-loc {
      display: inline-flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 500;
      p {
        margin: 0 0.5rem;
      }
      svg {
        font-size: 1rem;
        &:first-child {
          color: $color-accent;
        }
      }
    }
    .br-text {
      display: flex;
      align-items: center;
      position: relative;
      svg {
        margin-right: 0.5rem;
      }
      p {
        margin: 0;
        font-weight: 500;
      }
      span {
        display: none;
        position: absolute;
        font-size: 0.7rem;
        bottom: -0.8rem;
        left: 1.6rem;
      }
    }
    .top-filters {
      display: flex;
      > div {
        margin-left: 1rem;
      }
    }
    @include respond-to("md") {
      display: flex;
    }
  }

  @include respond-to("md") {
    grid-template-columns: 25rem 1fr;
    column-gap: 4rem;
    .category {
      display: flex;
    }
    .products {
      // .top-search-mobile {
      //   display: none;
      // }

      .p-items {
        .sk-ld {
          justify-content: flex-start;
        }
        .product-items {
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          justify-items: start;
          gap: 4rem 2rem;
        }
        .p-cat {
          font-size: 1.7rem;
          font-weight: bold;
          justify-content: flex-start;
          margin: 0;
          margin-bottom: 3rem;
          svg {
            display: block;
          }
        }
        .c-cat {
          justify-content: start;
          margin: 1rem 5px;
        }
      }
    }
  }
}
.profile-center {
  display: grid;
  grid-template-columns: 1fr;
  // text-align: center;
  img {
    border-radius: 100%;
    background-color: $color-accent;
  }
}
// .card {
//   box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
// }

// .card {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 0 solid rgba(0,0,0,.125);
//   border-radius: .25rem;
// }

// .card-body {
//   flex: 1 1 auto;
//   min-height: 1px;
//   padding: 1rem;
// }
// table {
//   // border-collapse: collapse;
//   width: 100%;
// }

// th{
//   text-align: left;
//   color: $color-accent;
//   padding: 8px;
// }
// td {
//   text-align: left;
//   padding: 8px;
// }

// tr:nth-child(even) {background-color: #f2f2f2;}
#account .account-button {
  background-color: $color-accent;
  padding: 2px 12px;
  color: white;
  font-size: 14px;
}
.d-flex {
  display: flex;
}
#account .customize-p {
  font-size: 13px;
}
#account .customize-h4 {
  margin: 12px;
}
.cardDate {
  font-size: 12px;
}

.order-card {
  position: relative;
  animation: pop-up 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  opacity: 0;
  display: flex;
  flex-direction: row;
  max-width: 35rem;
  // width: 90%;
  align-items: flex-start;

  // border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  // padding-bottom: 1.5rem;
  * {
    box-sizing: border-box;
    font-family: "Montserrat";
  }
  .c-span {
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    background-color: $color-accent;
    font-size: 0.7rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    padding: 5px 0;
  }
  .f-sign {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    font-size: 1rem;
    background-color: #ffffff;
    padding: 2px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  &-top {
    position: relative;
    margin-right: 1rem;
    .p-c-img {
      display: grid;
      height: 5rem;
      width: 6rem;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      background-color: #fafafa;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        object-fit: cover;
        height: 100%;
        transition: 0.3s;
        // border-radius: 3px;
      }
    }
  }
  &-details {
    flex: 1;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 0;
    box-shadow: none;
    border-radius: 5px;
    z-index: 2;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
    padding: 10px;
    // margin-top: -10px;
    transition: 0.2s;
    .det-top {
      .p-name {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0.2rem;
        margin: 0;
        text-align: left;
        > span {
          font-weight: 500;
        }
      }
      .p-content {
        margin: 5px 0;
        font: 400 0.8rem "Cormorant Garamond";
      }
      .p-price {
        font-weight: bold;
        color: $color-accent;
        span {
          text-decoration: line-through;
          font-weight: normal;
          font-size: 0.8rem;
          margin-right: 5px;
          color: #999;
        }
      }
    }
    .det-bottom {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      margin-top: 1rem;
      .p-actions {
        ul {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;
          padding: 0;
          cursor: pointer;
          li {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 5px;
            font-size: 1.2rem;
            & > svg {
              font-size: 1.3rem;
              box-sizing: content-box;
              padding: 2px;
              transition: 0.2s;
            }
          }
          .p-c-rating {
            display: inline-flex;
            align-items: center;
            font-size: 0.7rem;
            border-radius: 0;
            margin-left: 5px;
            cursor: default;
            svg {
              color: orange;
              padding: 0;
              margin-right: 0.3rem;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .p-allergens {
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 0;
      > div {
        svg {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
    .p-exp {
      @include popUp();
      display: inline-flex;
      position: absolute;
      bottom: 100%;
      left: -1rem;
      padding: 10px;
      background-color: #fff;
      border-radius: 3rem;
      box-shadow: 0 5px 1rem rgba($color: #000000, $alpha: 0.1);
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        left: 1.5rem;
        bottom: -1rem;
        border-width: 10px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
      }
      & > span {
        position: relative;
        font-size: 1.5rem;
        margin: 0 5px;
        span {
          position: absolute;
          font-size: 0.8rem;
          bottom: 100%;
          left: 0;
          background-color: #000;
          white-space: nowrap;
          color: #fff;
          padding: 2px 10px;
          border-radius: 1rem;
          margin-left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: 0.2s;
        }
        &:hover {
          opacity: 0.8;
          span {
            opacity: 1;
          }
        }
      }
    }
    .a-c-btn {
      position: absolute;
      right: 7px;
      bottom: 8px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      svg {
        box-sizing: content-box;
        box-shadow: 0 0 0 1px $color-accent;
        color: #fff;
        font-size: 1rem;
        border-radius: 2rem;
        padding: 0.6rem;
        transition: 200ms;
        background-color: #fff;
        color: $color-accent;
        &:hover {
          background-color: $color-accent;
          transform: rotate(90deg);
          color: #fff;
          box-shadow: none;
        }
        &:active {
          transform: rotate(90deg) scale(0.9);
        }
      }
      @include ttip-hover();
    }
  }
  &:hover & {
    &-top {
      position: relative;
      .p-c-img {
        img {
          transform: scale(1.1);
          filter: brightness(80%);
        }
      }
    }
    &-details {
      background-color: #fafafa;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1),
        0 0 0 1px rgba($color: #000000, $alpha: 0.05);
    }
  }
  &:hover {
    cursor: pointer;
  }

  @include respond-to("md") {
    flex-direction: column;
    max-width: 28rem;
    width: 100%;
    align-items: stretch;
    .f-sign {
      top: 1rem;
      left: 1rem;
      font-size: 1.2rem;
    }
    .c-span {
      top: auto;
      bottom: 0;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0.6rem 0 1rem 0;
    }
    &-top {
      position: relative;
      margin: 0;
      .p-c-img {
        height: 13rem;
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          height: 15rem;
          transition: 0.3s;
        }
      }
    }

    &-details {
      padding: 1rem;
      padding-bottom: 10px;
      margin-top: -10px;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      .det-top {
        .p-name {
          margin: 5px 0 1rem 0;
        }
        .p-content {
          margin: 5px 0;
          min-height: 2rem;
          font: 500 0.9rem "Cormorant Garamond";
        }
      }
      .det-bottom {
        .p-actions {
          ul {
            padding: 10px 0;
            .p-c-rating {
              font-size: 0.9rem;
            }
          }
        }
      }
      .p-allergens {
        > div {
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      .a-c-btn {
        right: 1rem;
        bottom: 1rem;
        svg {
          padding: 0.8rem;
        }
      }
    }
  }
}

.u-clearfix:before,
.u-clearfix:after {
  content: " ";
  display: table;
}

.u-clearfix:after {
  clear: both;
}

.u-clearfix {
  *zoom: 1;
}

.subtle {
  color: #aaa;
}

.card-container {
  margin: 25px auto 0;
  position: relative;
  // width: 692px;
}

.card {
  background-color: #fff;
  padding: 30px;
  position: relative;
  box-shadow: 0 0 5px rgba(75, 75, 75, 0.07);
  z-index: 1;
}

.card-body {
  display: inline-block;
  float: left;
  width: 310px;
}

.card-number {
  margin-top: 15px;
}

.card-circle {
  border: 1px solid $color-accent;
  border-radius: 50%;
  display: inline-block;
  line-height: 22px;
  font-size: 12px;
  height: 25px;
  text-align: center;
  width: 25px;
}

.card-author {
  display: block;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 15px 0 0;
  text-transform: uppercase;
}

.card-title {
  font-size: 40px;
  font-weight: 300;
  line-height: 60px;
  margin: 10px 0;
  color: $color-accent;
}

.card-description {
  display: inline-block;
  font-weight: 300;
  line-height: 22px;
  margin: 10px 0;
}

.card-read {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 5px 0 20px;
  position: relative;
  color: $color-accent;
}

.card-tag {
  float: right;
  margin: 5px 0 0;
}

.card-media {
  float: right;
  // width: 100%;
}

.card-shadow {
  background-color: #fff;
  box-shadow: 0 2px 25px 2px rgba(0, 0, 0, 1), 0 2px 50px 2px rgba(0, 0, 0, 1),
    0 0 100px 3px rgba(0, 0, 0, 0.25);
  height: 1px;
  margin: -1px auto 0;
  width: 80%;
  z-index: -1;
}
.scroll-bar {
  scroll-behavior: smooth;
  height: 40rem;
  overflow-y: auto;
  background-color: #fff;
}
.text-center {
  text-align: center !important;
}

.empty-image {
  @include respond-to("md") {
    max-width: 55%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  max-width: 100%;
  // height: auto;
  // // vertical-align: middle;

  // width: 100%;
  // max-width: 550px;
}
.detail-img {
  @include respond-to("sm") {
    width: 308px;
  }
  @include respond-to("xs") {
    width: 308px;
  }
  @include respond-to("md") {
    width: 400px;
  }
}
.order-now-button {
  border: 1px solid $color-accent !important;
  padding: 7px 9px;
  color: white;
  background-color: $color-accent;
}
