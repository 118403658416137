@keyframes fadeOutAmin {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes fadeInAmin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pop-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes sk-anim-1 {
  0% {
    opacity: 0.3;
    transform: translateY(0);
    // background-color: #ddd;
  }
  100% {
    opacity: 1;
    transform: translateY(-5px);
    // background-color: transparent;
  }
}
@keyframes dashAnim {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 200;
  }
  50% {
    stroke-dashoffset: -20;
    stroke-dasharray: 80 100;
  }
  100% {
    stroke-dashoffset: -120;
    stroke-dasharray: 20 150;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@mixin popUp($delay: 0s) {
  animation: pop-up 0.3s $delay;
}
@mixin playAnimation($name, $time, $delay, $forwards: '') {
  animation: $name $time $delay $forwards ease-out;
}

@mixin widthAnim($width) {
  @keyframes anim {
    0% {
      width: 0%;
    }
    100% {
      width: $width;
    }
  }
  animation: anim 0.1s forwards;
}

@mixin heightAnim($height) {
  @keyframes anim {
    0% {
      height: 0%;
    }
    100% {
      height: $height;
    }
  }
  animation: anim 0.1s forwards;
}

@keyframes imgAnim {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    filter: none;
    transform: scale(1);
  }
}

@keyframes rippleBox {
  0% {
    box-shadow: 0 0 0 0 $color-accent;
  }
  100% {
    box-shadow: 0 0 0 5px transparent;
  }
}
