.adding {
  padding: 5px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  border-bottom-color: #fff;
  animation: rotate 0.5s linear infinite;
  margin-right: 10px;
}
.button-round {
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.button-trans {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: none;
}
.spin-loader {
  border: 5px solid #ffd600;
  padding: 10px;
  margin: 10px;
  border-radius: 30px;
  border-bottom-color: #ff9800;
  animation: rotate 0.5s infinite linear;
}
.dot {
  position: absolute;
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  background: red;
  top: -0.2rem;
  right: -0.5rem;
  overflow: hidden;
  /* box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0 0 3px #fff;
}
.dot p {
  margin: 0;
  padding: 0;
  color: #fff;
  position: absolute;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: 'Montserrat';
}
p {
  margin: 0;
}

.dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 2px;
  padding: 0;
}
.dropdown .label {
  font-size: 10px;
  margin: auto 2px;
  font-weight: bold;
}
.dropdown ul {
  margin: 0;
  padding: 0;
}
.dropdown li {
  list-style-type: none;
}
.dropdown .insets {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
  transition: border 0.1s;
}
.dropdown .insets p {
  margin-right: 10px;
  padding: 0;
}
.dropdown .insets:hover {
  border: 1px solid #d50000;
}
.dropdown .d-container {
  position: absolute;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  top: 100%;
  left: 5%;
  display: none;
  overflow: hidden;
  z-index: 10;
}
.dropdown .d-list li {
  font-size: 12px;
  padding: 5px 10px;
  cursor: pointer;
}
.dropdown .d-list li:hover {
  background-color: #eee;
}
/* .dropdown .insets:hover ~.d-container{
    display: block
} */

.pizzaIcon svg {
  cursor: pointer;
  /* border:2px solid transparent; */
  transition: 0.3s;
  padding: 8px;
  border-radius: 30px;
  /* background: #ffd600; */
  /* background:linear-gradient(to bottom right,#ffd600,#ff9100); */
  color: #000;
  /* box-shadow: 3px 3px 10px #ff910090, -2px -2px 5px #ffffff; */
}
.pizzaIcon:hover svg {
  /* border-color: #fff; */
  color: #555;
}
.loc {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  animation: popup 0.3s forwards;
  z-index: 10;
}
.loc .loc-input {
  padding: 15px;
  border: none;
  outline: none;
  /* border-radius: 1vh; */
  flex: 1;
  /* font-family: 'Open Sans',''; */
  font-size: 1.1rem;
  font-family: 'Montserrat';
}
.loc ul {
  /* display: flex;
flex-direction: column; */
  position: absolute;
  padding: 5px 0;
  background-color: #fff;
  width: 100%;
  top: 92%;
  border-radius: 2px 2px 5px 5px;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  text-align: start;
  transition: 0.3s;
  /* align-items: flex-start; */
  /* z-index:30; */
}
.loc ul li {
  /* width:100%; */
  position: relative;
  list-style-type: none;
  font-size: 13px;
  padding: 15px 0;
  margin: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.1s;
  animation: popup 0.1s ease;
  border-radius: 5px;
}

.loc ul li span {
  margin: 0 10px 0 10%;
  font-family: 'Montserrat';
  font-weight: bold;
}
.loc ul li .loc-icon {
  position: absolute;
  margin: 0 2%;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 5px;
  border-radius: 50%;
  transition: 0.1s;
  background: #fff;
  z-index: 20;
}
.loc ul li:hover {
  background-color: #d5000023;
  cursor: pointer;
  /* box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05); */
  color: #d50000;
}
.loc ul li:hover .loc-icon {
  background: transparent;
  animation: smoothSlide 0.3s;
}
/* .loc ul li:hover span {
  color: #fff;
} */
.loc .locate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #d50000;
  align-self: stretch;
  /* margin: 5px; */
  padding: 0 15px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: 0.2s;
  /* box-shadow: -2px 0 10px rgba(0,0,0,0.5); */
}
.loc .locate:hover {
  background: #eee;
  box-shadow: none;
}
.loc .locate p {
  font-size: 12px;
  /* display: none; */
  transition: 0.1s;
  /* width: 0%; */
  overflow: hidden;
  padding: 0 15px 0 5px;
  font-weight: bold;
}
.loc .locate span {
  padding: 0.5rem;
  border-width: 3px;
  border-color: #d50000;
  border-top-color: #d5000045;
  margin: 0;
}
.submit {
  background-color: #d50000;
  color: #fff;
  border-radius: 5px;
  margin: 20px 0px;
  padding: 1rem 2rem;
  font-size: 15px;
  font-weight: bold;
  transition: 0.2s;
  font-family: 'Montserrat';
  opacity: 0;
  animation: popup 0.3s 0.1s forwards;
}
.submit:hover {
  background-color: #fff;
  color: #000;
}
.choice {
  display: flex;
  flex-direction: row;
  background: #fff;
  text-align: center;
  margin-bottom: 40px;
  border-radius: 3px;
  /* width: 40vw; */
  font-size: 13px;
  align-self: center;
  /* cursor: pointer; */
  color: #fff;
  overflow: hidden;
  /* border:5px solid #fff; */
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}
.choice p {
  white-space: nowrap;
}
.choice > div {
  display: inline-flex;
  font-size: 0.8rem;
  align-items: center;
  color: #000;
  padding: 13px;
}
.choice .delivery {
  /* flex:1; */
  border-radius: 3px;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;
}
.choice .pick-up {
  /* flex:1; */
  /* border-radius: 5px; */
  transition: 0.2s;
  /* color: rgba(0, 0, 0, 0.8); */
  cursor: pointer;
  display: flex;
  align-items: center;
}
.choice > div:hover:not(.selected) {
  background-color: #eee;
}
/* .delivery:hover {
  background-color: rgba(255, 255, 255, 0.2);
} */
/* .pick-up:hover{background-color: rgba(255,100,80,0.2)} */
.choice .selected {
  background-color: #d5000013;
  color: #d50000;
  font-weight: bold;
  /* box-shadow: 0 2px 5px rgba(0,0,0,0.5); */
}
.offers {
  overflow-x: scroll;
  position: relative;
  white-space: nowrap;
  scrollbar-width: none;
  margin: auto;
  /* padding: 5px 0; */
}
.offers::-webkit-scrollbar {
  width: 0 !important;
}
.offers img {
  height: 25vh;
  object-fit: fill;
  width: 95vw;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
}
.offers .control {
  position: absolute;
}
.offers .control * {
  background-color: #fff;
  padding: 10px;
  border-radius: 20vw;
  align-self: center;
  cursor: pointer;
  margin: 0px 5px;
  box-shadow: 0px 2px 10px 0px #000;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  animation: fadein 0.2s;
}
.loading .loader-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffffdd;
  /* background: rgba(0, 0, 0, 0.8); */
  pointer-events: none;
}
.loading .loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  padding: 20px 40px;
  min-width: 100px;
  font-weight: bold;
  background: #fff;
  color: #000;
  font-family: 'Montserrat';
  font-size: 1.3rem;
  z-index: inherit;
  border-radius: 5px;
  box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.05);
}
.loading .loader .icon {
  animation: roam 1s infinite;
  /* color:rgba(255,255,255,0.2); */
  font-size: 10px;
  width: 40px;
}

.m-bottom-bar {
  display: none;
}

.h-track-cont {
  display: none;
  opacity: 1;
  position: absolute;
  padding: 10px 20px;
  top: 140%;
  /* right:-10%; */
  background: #fff;
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat';
  animation: popup 0.2s;
}
.h-track-cont::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  transform: rotate(45deg);
  top: -5px;
  border-style: solid;
  border-width: 5px;
  border-top-color: #fff;
  border-left-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  cursor: default;
}
.h-track-cont .h-track-head {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  color: #000;
}
.h-track-cont .h-track-head p {
  margin: 5px 0;
}
.h-track-cont form {
  display: flex;
  flex-direction: column;
}
.h-track-cont form input {
  padding: 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 1rem;
  box-shadow: none;
  transition: 0.4s;
  font-family: 'Montserrat';
}
.h-track-cont form input:focus {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* border-radius: 5px; */
}
.h-track-cont form button {
  padding: 10px;
  cursor: pointer;
  border: none;
  background: #fff;
  color: #000;
  border-radius: 3px;
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); */
  margin: 15px 0;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: 0.2s;
  font-family: 'Montserrat';
}
.h-track-cont form button:hover {
  background: #00000005;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.vn-logo {
  padding: 5px;
  position: absolute;
  border: 1px solid green;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vn-logo::after {
  content: '';
  padding: 3px;
  position: absolute;
  background: green;
  border-radius: 5px;
}
.test-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
}
.test-container .banner {
  padding: 5px 0;
  position: fixed;
  top: 20px;
  right: -40px;
  background-color: #d50000;
  color: #fff;
  font-size: 12px;
  transform: rotate(45deg);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 150px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
}
.s-top {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  background: #fff;
  /* background:linear-gradient(#eee,#fff); */
  color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 5%;
  right: 2%;
  cursor: pointer;
  animation: popout 0.5s;
}

.cctv-cont {
  z-index: 100;
  background-color: #00000045;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadein 0.2s;
}
.cctv-cont div {
  position: relative;
  min-width: 70vh;
  min-height: 60vh;
  background-color: #000;
  /* border-radius: 5px; */
  box-shadow: 0 10px 1rem rgba(0, 0, 0, 0.3);
  animation: popup 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cctv-cont div .spin-loader {
  position: absolute;
}
.cctv-cont h2 {
  color: #fff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.cctv-cont div iframe {
  z-index: 10;
}
.cctv-cont .close-icon {
  color: #fff;
  position: absolute;
  font-size: 2rem;
  right: -3rem;
  top: 0;
  cursor: pointer;
}
@media only screen and (max-width: 500px) {
  .cart {
    width: 100%;
    /* border-top-left-radius: 0; */
  }
  .pizzaIcon svg {
    padding: 5px;
    font-size: 10px;
  }
  .dot {
    z-index: 1;
  }

  .loc {
    width: 90vw;
  }
  .loc .locate span {
    font-size: 10px;
    white-space: nowrap;
  }

  .cart .checkout {
    position: sticky;
    bottom: 0;
    left: 0;
    /* background-color: #00c853; */
    /* margin:2% 5%; */
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
    /* border-radius: 5px; */
    overflow: hidden;
  }
  .m-bottom-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
    align-self: center;
    /* padding: 10px; */
    /* border-radius: 5px; */
    overflow: hidden;
    left: 0%;
    right: 0%;
    bottom: 0px;
    animation: popout 0.5s;
    font-family: 'Montserrat';
    /* height: 5vh; */
    font-weight: bold;
  }
  .m-bottom-bar .m-total {
    display: inline-flex;
    font-size: 12px;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 10px;
  }
  .m-bottom-bar .m-total span {
    font-size: 15px;
    margin: 0 5px;
    color: #000;
  }
  .m-bottom-bar .m-check {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    padding: 15px 10px;
  }
 
  .content .add-ons .max-span {
    background-color: #fff;
    box-shadow: none;
    bottom: -15px;
    right: 10px;
  }

  .cctv-cont {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .cctv-cont div {
    min-width: 50vh;
    min-height: 50vh;
  }
  .cctv-cont h2 {
    font-size: 1.2rem;
    white-space: pre-line;
  }
  .cctv-cont .close-icon {
    right: 40%;
    bottom: -30%;
    top: auto;
    z-index: 100;
    background-color: #000;
    padding: 10px;
    border-radius: 50%;
  }
}
@media (min-width: 992px) {
  .offers img {
    height: 30vh;
    width: 50vw;
  }
  .offers {
    width: 70%;
  }
  .loc {
    width: 60vh;
  }
  .choice {
    width: auto;
  }
}
