.error-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg{
      font-size: 3rem;
      color: $color-accent;
    }
    div {
      font-family: 'Montserrat';
      margin: 20px;
      text-align: center;
      span {
        font-weight: bold;
        font-size: 2rem;
        color: $color-accent;
      }
      p {
        font-size: 15px;
        margin-top: 10px;
        max-width: 300px;
      }
    }
  }
  button {
    font-family: 'Montserrat';
    padding: 10px 20px;
    border-radius: 2rem;
    border: none;
    cursor: pointer;
    background: $color-accent;
    color: #fff;
    margin: 20px 0;
    font-weight: bold;
  }
}
@media only screen and (max-width: 500px) {
  .error-cont {
    .err-content {
      width: 40vw;
    }
  }
  .error-page {
    > div {
      width: 80%;
      border-radius: 5px;
    }
  }
}
