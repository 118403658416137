.aizo-root {
  animation: fadein 0.3s;
  // min-height: 100vh;
  .back-img {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 100%;
    height: 100vh;
    object-fit: contain;
  }
  .root-main {
    position: relative;
    margin: auto;
    backdrop-filter: blur(5px);
    z-index: 100;
    max-width: 800px;
    min-height: 100vh;
    border-radius: 20px;
    @include respond-to("md") {
      min-height: auto;
      margin-top: 7rem;
      background: rgba(255, 255, 255, 0.65);
      border-radius: 16px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      backdrop-filter: blur(14.4px);
      -webkit-backdrop-filter: blur(14.4px);
      border: 1px solid rgba(255, 255, 255, 0.66);
    }
  }
  &-menu {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    transition: 0.3s;
    padding: 1.5rem 2rem;
    margin: auto;
    .menu-back {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.05);
      transition: 0.2s;
      &--show {
        background-color: #fffffff9;
        height: 3rem;
      }
      &--hidden {
        background-color: transparent;
      }
    }
    .title {
      font-weight: bold;
      font-size: 1.5rem;
      letter-spacing: -1px;
      text-transform: uppercase;
      align-items: center;
      span {
        display: block;
        font-size: 1rem;
        line-height: 5px;
      }
      img {
        width: 5rem;
        object-fit: contain;
        object-position: left;
      }
    }
    .n-opt {
      display: inline-flex;
      > div {
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        padding: 10px;
        transition: 0.1s;
        font-size: 0.9rem;
        color: #000;
        margin-left: 0.5rem;
        span {
          display: none;
        }
        svg {
          font-size: 1.1rem;
          margin-right: 0;
        }
        &:hover {
          background-color: #fff;
          box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
        }
      }
    }

    @include respond-to("md") {
      // background-color: #ffffff57;
      padding: 1rem 2rem;
      .title {
        display: inline-flex;
        font-size: 1.3rem;
        color: #fff;
        padding: 0;
        img {
          width: 8rem;
          height: 5rem;
          margin: 0;
        }
      }
      ul {
        display: inline-flex;
      }
      .n-opt {
        > div {
          display: inline-flex;
          margin-left: 1rem;
          span {
            display: block;
          }
          svg {
            margin-right: 0.5rem;
          }
        }
      }
      .menu-back {
        &--show {
          height: 5rem;
        }
      }
    }
  }
  .dish-img {
    position: absolute;
    z-index: 1;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: #eee;
    box-shadow: 0 2rem 1rem rgba($color: #000000, $alpha: 0.1);
    // opacity: 0.1;
    img {
      // display: none;
      height: 100%;
      object-fit: cover;
      transform: scale(1.1);
    }
  }
  .img-1 {
    opacity: 0.3;
    filter: blur(2px);
    left: 0%;
    top: 30%;
    width: 10rem;
    height: 10rem;
    // opacity: 0.5;
    transform: rotate(30deg);
  }
  .img-2 {
    filter: blur(2px);
    top: 60%;
    left: 10%;
    width: 12rem;
    height: 12rem;
    // opacity: 0.5;
    transform: rotate(20deg);
  }
  .img-3 {
    top: 50%;
    left: -10%;
    width: 20rem;
    height: 20rem;
    // opacity: 0.5;
  }
  .img-4 {
    opacity: 0.5;
    filter: blur(2px);
    top: 75%;
    left: 40%;
    width: 10rem;
    height: 10rem;
  }
  .img-5 {
    filter: blur(2px);
    top: 60%;
    right: 10%;
    width: 15rem;
    height: 15rem;
    object-fit: fill;
  }
  .img-6 {
    opacity: 0.5;
    filter: blur(2px);
    top: 20%;
    right: 5%;
    width: 10rem;
    height: 10rem;
  }
  @include respond-to("md") {
    .back-img {
      object-fit: fill;
      width: 100%;
    }
  }
}
.root-back {
  position: relative;
  & > svg {
    fill: #{$color-accent}97;
  }
}
.search-cont {
  position: relative;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  transition: 0.5s;
  .s-back {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    @extend .spread;
    bottom: auto;
    height: 0;
    background-color: #fff;
    z-index: 5;
    transition: 0.5s;
    box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
    .s-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 2rem;
      cursor: pointer;
    }
    .s-panel {
      box-sizing: border-box;
      @include popUp(0.3s);
      animation-fill-mode: forwards;
      opacity: 0;
      margin-top: 8rem;
      width: 90%;
      & > span {
        display: block;
      }
      .s-d-type {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 1rem 0;
        gap: 1rem;
        & > div {
          position: relative;
          display: inline-flex;
          align-items: center;
          box-sizing: border-box;
          padding: 1rem;
          border-radius: 5px;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.05),
            0 2px 3px rgba($color: #000000, $alpha: 0.05);
          cursor: pointer;
          &:hover {
            box-shadow: 0 0 0 1px $color-accent;
          }
          svg {
            margin-right: 1rem;
            font-size: 1.2rem;
          }
          .radio {
            position: absolute;
            right: 0;
            color: rgba($color: #000000, $alpha: 0.2);
          }
        }
        .selected {
          background-color: $color-accent;
          color: #fff;
          font-weight: bold;
          box-shadow: none;
        }
      }
      .s-list {
        overflow-y: auto;
        height: auto;
        background-color: #fff;
        ul {
          padding: 0 2px;
          li {
            display: grid;
            grid-template-columns: 2rem 1fr;
            min-height: 3rem;
            box-sizing: border-box;
            padding: 10px;
            font-weight: bold;
            // border: 0;
            // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
            cursor: pointer;
            margin: 10px;
            border-radius: 4px;
            svg {
              margin-top: 2px;
            }
            p {
              margin: 0;
              font-size: 13px;
            }
            &:hover {
              background-color: rgba($color: $color-accent, $alpha: 1);
              // background-color: rgba(161 110 110 / 15%);
              color: $color-accent;
              svg {
                color: $color-accent;
              }
            }
          }
        }
        .a-loader {
          position: absolute;
          top: 15rem;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }
  }
  .search-box {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: #fff;
    transition: 0.2s box-shadow, 0.3s top;
    z-index: 10;
    border-radius: 2px;
    cursor: pointer;
    .s-input {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding-right: 8px;
      flex: 1;
      height: 55px;
      min-width: 85vw;
      box-sizing: border-box;
      svg {
        position: absolute;
        margin: 0 1rem;
        color: $color-accent;
        transition: 0.3s;
      }
      input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        transition: 0.3s;
        border-radius: 5px;
        &:disabled {
          background-color: transparent;
        }
        .outlet-select {
          padding: 16px;
          width: 100%;
          background-color: white;
          border: none !important;
          transition: 0.3s;
          font-size: 1.1rem;
        }
        .outlet-select:focus-visible {
          border: none !important;
        }
        .outlet-select:focus {
          border: none !important;
        }
      }
      .inp-ph {
        position: absolute;
        font-size: 1.5rem;
        color: #ccc;
        pointer-events: none;
        transform-origin: left center;
      }
      &:hover {
        box-shadow: 0 0 0 1px $color-accent;
      }
    }
    .s-btn {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: grid;
      place-items: center;
      color: $color-accent;
      padding: 0 1.5rem;
      transition: 0.3s;
    }
    .s-c-loc {
      @include popUp(0.1s);
      animation-fill-mode: both;
      opacity: 21;
      position: absolute;
      right: 0;
      height: 3.5rem;
      width: 3.5rem;
      font-size: 1.3rem;
      padding: 1rem;
      border-radius: 8px;
      @include hovBox();
    }
    &--active {
      top: 2rem;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      .s-input {
        svg {
          font-size: 1.3rem;
        }
        input {
          padding: 1rem;
          padding-left: 3rem;
          font-size: 1rem;
        }
        .outlet-select {
          padding: 16px;
          width: 100%;
          background-color: white;
          border: none;
          outline: none;
          border-radius: 5px;
          transition: 0.3s;
          font-size: 1.1rem;
        }
        .outlet-select:focus-visible {
          border: "none" !important;
        }
        .outlet-select:focus {
          border: none !important;
          // background-color: rgb(218, 25, 25);
        }
      }
      .s-btn {
        font-size: 0;
      }
    }
    &--focus {
      border: 0px !important;
    }
    &--inactive {
      top: 30%;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1),
        0 0 0 1px rgba($color: #000000, $alpha: 0.05);
      .s-input {
        padding: 1rem;
        svg {
          font-size: 0;
        }
        input {
          font-size: 1.2rem;
        }
      }
      .s-btn {
        font-size: 1.5rem;
      }
    }
  }
  @include respond-to("md") {
    .s-back {
      .s-close {
        top: 3rem;
        right: 3rem;
        font-size: 2rem;
      }
      .s-panel {
        margin-top: 6rem;
        width: 30rem;
        & > span {
          display: block;
        }
        .s-d-type {
          gap: 1rem;
          & > div {
            border-radius: 5px;
            svg {
              margin-right: 1rem;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .search-box {
      border-radius: 5px;
      .s-input {
        min-width: 30rem;
        .inp-ph {
          font-size: 2rem;
        }
      }
      .s-c-loc {
        right: -15%;
        height: 3.5rem;
        width: 3.5rem;
        font-size: 1.5rem;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
      }

      &--active {
        top: 2rem;
        .s-input {
          svg {
            font-size: 1.5rem;
          }
          input {
            padding: 1rem;
            padding-left: 4rem;
            font-size: 1.1rem;
          }
        }
        .s-btn {
          font-size: 0;
        }
      }
      &--inactive {
        top: 40%;
        .s-input {
          padding: 1rem 2rem;
          input {
            font-size: 2rem;
          }
        }
        .s-btn {
          font-size: 2rem;
        }
      }
    }
  }
}
.l-head-info {
  font-weight: bold;
  text-align: center;
  color: $color-accent;
  font-size: 0.9rem;
}
.content-box {
  margin: 0 5rem;
  text-align: center;
}
.special-section {
  display: block;
  margin-top: 10rem;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    .a-f {
      @extend .center-content;
      width: 4rem;
      height: 4rem;
      font-size: 1.5rem;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
      border-radius: 50%;
      margin-left: 2rem;
      @include hovBox() {
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
}
.a-title {
  display: block;
  margin: 3rem;
  p {
    font-weight: bold;
    font-size: 3rem;
  }
}

.dish-card {
  position: relative;
  margin: 2rem;
  // max-width: 30rem;
  width: 15rem;
  * {
    box-sizing: border-box;
  }
  &-img {
    width: 8rem;
    height: 8rem;
    border-radius: 1rem;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
    overflow: hidden;
    margin: 0 0 -3rem 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-body {
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1),
      0 2px 5px rgba($color: #000000, $alpha: 0.1);
    padding: 1rem;
    padding-top: 5rem;
    border-radius: 1rem;
    cursor: pointer;
    p {
      font-weight: bold;
      font-size: 1.1rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
}

.image-card {
  position: relative;
  margin: 2rem;
  // max-width: 30rem;
  width: 15rem;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
  &-img {
    width: 15rem;
    height: 20rem;
    // border-radius: 1rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    color: #fff;
    background: linear-gradient(transparent, #000);
    transition: 0.2s;
    // min-height: 10rem;
    p {
      font-weight: bold;
      font-size: 1.1rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
  .price {
    display: inline-block;
    background-color: #fff;
    color: #000;
    padding: 0 10px;
    border-radius: 3rem;
    margin-top: 10px;
    font-weight: bold;
  }
  &:hover & {
    &-img {
      img {
        transform: scale(1.1);
      }
    }
    &-body {
      padding-top: 5rem;
    }
  }
}

.loc-search {
  position: relative;
  padding: 3rem 0;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .sk-menu-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
    transition: 0.2s;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    border: 1px solid #fff;
    font-size: 0.8rem;
    background-color: $color-accent;
    svg {
      margin-left: 10px;
      background-color: $color-accent;
      color: #fff;
      padding: 4px;
      border-radius: 50%;
    }
    &:hover {
      background-color: $color-accent;
      color: #fff;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      svg {
        background-color: $color-accent;
        color: #fff;
      }
    }
  }
  .d-type {
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
    background-color: #fff;
    & > div {
      display: inline-flex;
      align-items: center;
      color: #999;
      cursor: pointer;
      transition: 0.1s;
      font-size: 0.9rem;
      padding: 0.8rem 1rem;
      svg {
        font-size: 1rem;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      span {
        font-weight: 500;
      }
      &:hover:not(.t-selected) {
        background-color: rgba($color: #000000, $alpha: 0.03);
        color: $color-accent;
      }
    }
    .t-selected {
      color: $color-accent;
      span {
        font-weight: 500;
      }
      svg {
        animation: popup 0.2s;
        box-shadow: 0 3px 5px rgba($color: $color-accent, $alpha: 0.5);
      }
    }
  }
  .loc-box {
    display: grid;
    column-gap: 5px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
    margin: 2rem 0;
    z-index: 10;
    & > div > div {
      position: relative;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      transition: 0.2s;
      width: 90vw;
      max-width: 30rem;
      &:first-child {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
      & > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.7rem;
        font-weight: bold;
        margin-left: 2px;
        svg {
          animation: fadein 0.2s;
          background-color: $color-accent;
          color: #fff;
          border-radius: 50%;
          font-size: 0.6rem;
          padding: 2px;
          margin-left: 0.5rem;
          box-shadow: none;
        }
      }
      input {
        font-size: 1rem;
        background-color: transparent;
        outline: none;
        margin-top: 5px;
        width: 100%;
      }
      .d-arrow {
        position: absolute;
        right: 1rem;
        transition: 0.2s;
      }
      ul {
        position: absolute;
        background-color: #fff;
        top: 100%;
        left: 0;
        right: 0;
        padding: 5px 0;
        margin-top: 2px;
        border-radius: 5px;
        max-height: 15rem;
        overflow-y: auto;
        animation: popup 0.2s;
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
        z-index: 5;
        & > span {
          display: block;
          padding: 2rem 0;
          margin: 0 auto;
          font: 200 1rem "Montserrat";
          text-align: center;
        }

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 1rem;
          transition: 0.1s;
          svg {
            margin-right: 10px;
          }
          &:hover:not(.selected) {
            background-color: #eee;
            // color: $color-accent;
          }
        }
      }
      .selected {
        background-color: $color-accent;
        color: #fff;
        font-weight: bold;
      }

      .city-list {
        li:first-child {
          position: sticky;
          top: -5px;
          background-color: #fff;
          border-bottom: 1px solid #ddd;
        }
      }
      &:hover {
        background-color: rgba($color: $color-accent, $alpha: 0.1);
      }
    }
  }
  .bottom-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    overflow: hidden;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
    background-color: #fff;
    & > div {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: 10px;
      background-color: $color-accent;
      color: #fff;
      padding: 1rem;
      font-weight: 500;
      font-size: 0.8rem;
      text-align: center;
      cursor: pointer;
      &:first-child {
        background-color: #fff;
        color: $color-accent;
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }
    }
    span {
      display: none;
      position: absolute;
      font: bold 0.7rem "Montserrat";
      left: 50%;
      transform: translate(-50%, -20%);
      top: 60%;
      background-color: #fff;
      padding: 6px;
      border-radius: 50%;
    }
    svg {
      font-size: 1.1rem;
    }
  }
  @include respond-to("md") {
    .sk-menu-btn {
      right: 1rem;
      font-size: 0.8rem;
      color: $color-accent;
      font-weight: bold;
      border: 1px solid $color-accent;
      background-color: #ffffff95;
    }
    .d-type {
      > div {
        font-size: 1rem;
      }
    }
    .loc-box {
      grid-template-columns: auto 20rem;
      & > div > div {
        padding: 0.8rem 1.2rem;
        width: auto;
        &:first-child {
          border-bottom: none;
        }
      }
    }
    .bottom-btn {
      & > div {
        padding: 0.9rem 1rem;
        font-size: 1rem;
      }
    }
  }
}
.t-overlay {
  animation: fadein 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #000000a9;
  & > div {
    background-color: #fff;
  }
  &-head {
    padding: 0.7rem;
    border-radius: 25px;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: $color-accent;
    color: white;
    width: fit-content;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 5px;
    // border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  }
  &-body {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    flex-direction: column;
    & > div {
      padding: 1rem;
      &:nth-child(2) {
        position: relative;
        display: flex;
        flex-direction: column;
        & > div {
          display: none;
        }
        & > span {
          position: relative;
          display: block;
          z-index: 1;
          cursor: pointer;
          border-radius: 3px;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          padding: 10px 1rem;
          font-size: 0.8rem;
          margin-bottom: 5px;
          margin-left: 2px;
          margin-right: 2px;
          // width: 150px;
          // margin: auto;
          &:hover:not(.selected) {
            background-color: #eee;
          }
        }
        .selected {
          background-color: $color-accent;
          color: #fff;
          font-weight: 500;
        }
        // &::after {
        //   content: '';
        //   position: absolute;
        //   width: 2px;
        //   background-color: #eee;
        //   height: 80%;
        //   z-index: 0;
        //   left: 1.7rem;
        // }
      }
    }
  }
  &-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    & > div {
      font-weight: 500;
      padding: 10px 1rem;
      background-color: $color-accent;
      color: #fff;
      margin-left: 1rem;
      cursor: pointer;
      &:first-child {
        background-color: transparent;
        color: #000;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}

/* Style the list items */
.nearest li {
  cursor: pointer;
  position: relative;
  padding: 6px;
  list-style-type: none;
  background: white;
  font-size: 15px;
  transition: 0.2s;
  position: relative;
  height: 33px;
  /* make the list items unselectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.childSpan {
  position: absolute;
}
/* Darker background-color on hover */
.nearest li:hover {
  background: $color-accent;
  color: white;
}

/* When clicked on, add a background color and strike out text */
.nearest li.checked {
  background: $color-accent;
  color: #fff;
}

/* Add a "checked" mark when clicked on */
.nearest li .checked::before {
  content: "";
  position: absolute;
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  top: 10px;
  left: 16px;
  transform: rotate(45deg);
  height: 15px;
  width: 7px;
}

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: "Montserrat";
  .mob-logo {
    position: fixed;
    top: 2rem;
    left: 1rem;
    img {
      width: 8rem;
    }
  }
  .back-img {
    animation: imgAnim 1s cubic-bezier(0.19, 1, 0.22, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    // width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
  &-box {
    animation: pop-up 1s;
    border: 1px solid #fff;
    width: 90%;
    margin-top: 7rem;
    backdrop-filter: blur(5px);
    background-color: #ffffff84;
    margin-bottom: 5rem;
    & > div {
      &:not(:last-child) {
        border-bottom: 1px solid #fff;
      }
    }
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      padding: 1rem;
      .l-logo {
        display: none;
        align-items: flex-start;
        color: #000;
        width: 40%;
        img {
          width: 50%;
          margin-top: 10px;
        }
      }
      .l-nav {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        a {
          position: relative;
          text-decoration: none;
          color: #000;
          font-weight: bold;
          padding: 10px 0;
          font-size: 0.7rem;
          transition: 0.5s;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: #000;
            z-index: -1;
            transition: 0.5s;
            opacity: 0;
          }
          &:hover {
            color: #fff;
            padding: 10px;
            &::after {
              height: 100%;
              top: 0;
              opacity: 1;
            }
          }
        }
        span {
          min-width: 5rem;
          text-align: center;
          cursor: pointer;
          background-color: #424242;
          color: #fff;
          padding: 1rem;
          border-radius: 10px;
          box-shadow: 0 0 0 1px #ffffff56;
          transition: 0.2s;
          font-size: 0.7rem;
          &:hover {
            background-color: #000;
          }
        }
      }
    }
    &-content {
      font: bold 1.1rem "Cormorant Garamond";
      padding: 1rem;
    }
    &-bottom {
      display: flex;
      flex-direction: column;
      padding: 0;
      & > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 2rem 0.5rem;
        // &:not(:last-child) {
        //   border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
        // }
        & > div {
          display: flex;
          flex-direction: row;
          overflow: hidden;
          border-radius: 10px;
          margin-top: 1rem;
          span {
            cursor: pointer;
            background-color: $color-accent;
            color: #fff;
            padding: 10px 1rem;
            white-space: nowrap;
            font-size: 0.7rem;
            transition: 0.2s;
            &:not(:last-child) {
              border-right: 1px solid #fff;
            }
            &:hover {
              filter: brightness(110%);
            }
          }
        }
      }
    }
  }
  @include respond-to("md") {
    .back-img {
      width: 100%;
    }
    .mob-logo {
      display: none;
    }
    &-box {
      width: 70%;
      // background-color: #ffffff84;
      margin-top: 5rem;
      margin-bottom: 0;
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      &-head {
        padding: 1rem 2rem;
        .l-logo {
          display: inline-flex;
        }
        .l-nav {
          a {
            font-size: 1rem;
          }
          span {
            cursor: pointer;
            padding: 1rem 2rem;
            font-size: 0.9rem;
          }
        }
      }
      &-content {
        padding: 3rem 5rem 3rem 40%;
      }
      &-bottom {
        // display: inline-flex;
        flex-direction: row;
        padding: 0 0 0 40%;
        & > div {
          min-width: 15rem;
          margin-right: 1rem;
          font-size: 1.5rem;
          font-weight: 500;
          background-color: #ffffffdd;
          &:not(:last-child) {
            border-right: none;
          }
          & > div {
            span {
              padding: 10px 1rem;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

input:focus {
  outline: 0px !important;
}
