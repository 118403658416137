.notif {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  /* border: 5px solid white; */
  background: white !important;
}
/* .notif .det-cont .title {
  color: transparent;
  display: none;
} */
.notif .det-cont .desc {
  font-weight: 300;
  font-size: 1rem;
  color: #1f1717;
  line-height: 1.8;
}
.notif .notif-icon {
  color: red;
  margin-right: 10px;
}
.toast {
  height: 3vh;
  width: 45vh;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.Toastify__close-button--default {
  color: black;
  border: 2px solid black;
  border-radius: 500px;
  width: 25px;
  height: 25px;
  opacity: 0.6;
  padding: 2px;
}

.Toastify__toast-body {
  padding: 2.5rem;
}
.title {
  font-size: 1rem;
  padding: 7px 30px 7px 5px;
  color: red;
}
.det-cont {
  font-family: "Montserrat";
}
