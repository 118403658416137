@import './animations.scss';

@mixin fill-viewport {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.crustflix {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  animation: fadein 0.5s;
  background-color: #222;
  &-intro {
    @include fill-viewport();
    z-index: 100;
    background-color: #000;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    letter-spacing: 10px;
    font-weight: bold;
    img {
      width: 50vh;
      @include playAnimation(fadeOutAmin, 0.3s, 2s, forwards);
    }
    > span {
      @include playAnimation(fadeOutAmin, 0.3s, 2s, forwards);
      transform: translateY(0);
    }
  }
  &-header {
    position: sticky;
    top: 0;
    background-color: #000;
    border-bottom: 1px solid #ffffff22;
    padding: 0 1rem;
    font-weight: bold;
    z-index: 50;
    // backdrop-filter: blur(2rem);
    img {
      width: 100px;
      height: 50px;
      object-fit: cover;
    }
    > span {
      color: #ffd600;
    }
  }
  &-main {
    animation: fadein 0.2s;
    min-height: 90vh;
    .cr-video-full {
      font-family: Montserrat;
      position: relative;
      //   position: sticky;
      //   top: 0;
      height: 70vh;
      background-color: #000;
      .cr-video-mask {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        padding: 1rem;
        // background-color: #ffffff23;
        border-radius: 5px;
        margin: 1rem;
        transition: 0.5s;
        span:first-child {
          display: inline-flex;
          align-items: center;
          margin-bottom: 10px;
          > :first-child {
            margin-right: 5px;
          }
          color: #ffffff67;
        }
        .ou-name {
          color: #ffffff89;
          font-weight: bold;
          transition: 0.5s;
        }
      }
      // &>video{
      //   width: 100%;
      //   height: 100%;
      // }
      .video-js {
        height: 100%;
        width: 100%;
        .vjs-big-play-button {
          top: 50%;
          left: 50%;
          width: 5rem;
          height: 5rem;
          background-color: #000;
          border-radius: 50%;
          color: #fff;
          transition: 0.3s;
          &:hover {
            background-color: #fff;
            color: #000;
          }
          .vjs-icon-placeholder::before {
            position: relative;
            margin: auto;
          }
        }
        .vjs-control-bar {
          background: linear-gradient(transparent, #000);
          height: 15%;
          align-items: center;
          .vjs-time-control {
            display: flex;
            align-items: center;
          }
          .vjs-button {
            > .vjs-icon-placeholder:before {
              position: relative;
            }
          }
        }
      }
      &:hover {
        .cr-video-mask {
          background-color: #ffffff33;
          .ou-name {
            color: #fff;
          }
        }
      }
    }
    .cr-content {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      span {
        color: #fff;
      }
      & > span {
        color: #fff;
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      .cr-management {
        margin: auto;
        width: 100vh;
        border: 1px solid #ffffff23;
        padding: 1rem;
        border-radius: 10px;
        & > span {
          color: #ffffffa9;
        }
        & > div {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          margin-top: 1rem;
          .m-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px;
            > span {
              color: #fff;
            }
            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              object-fit: cover;
              margin-bottom: 10px;
              box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.9);
            }
            // & > :first-child {
            //   padding: 1rem;
            //   font-size: 2rem;
            //   border-radius: 50%;
            //   margin-bottom: 10px;
            //   background-color: #ffd60012;
            //   color: #ffd600;
            // }
          }
        }
      }
    }
    .cr-deco {
      display: flex;
      flex-direction: column;
      margin: 1rem 2rem;
      .cr-heading {
        font-size: 1.4rem;
        color: #fff;
      }
      .cr-sub-heading {
        color: #ffffff54;
      }
    }
    .cr-video {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      // grid-template-rows: 250px 250px;
      row-gap: 1rem;
      padding: 1rem;
      //   flex-direction: row;
      //   flex-wrap: wrap;
      //   align-items: center;
      //   justify-content: center;
      z-index: 20;
      min-height: 80vh;
      .spin-loader {
        position: absolute;
      }
      &-card {
        font-family: Montserrat;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #333;
        color: #fff;
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.3);
        margin: 5px;
        border-radius: 5px;
        padding: 1rem;
        transition: 0.2s;
        min-width: 100px;
        min-height: 30vh;
        &--active {
          background-color: #ffd600;
          color: #000;
          font-weight: bold;
          .cr-video-card-play {
            color: #000;
            background-color: #00000022;
          }
        }
        &-name {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-transform: capitalize;
          span {
            position: absolute;
            bottom: 15%;
            left: 10px;
            margin-top: 5px;
            padding: 2px 10px;
            font-size: 0.8rem;
            background-color: #ffffff34;
            color: #fff;
            font-weight: 500;
            border-radius: 1rem;
          }
        }
        &-play {
          position: absolute;
          align-self: center;
          bottom: 30%;
          padding: 10px;
          background-color: #ffffff15;
          color: #fff;
          border-radius: 50%;
        }
        &-stat {
          display: inline-flex;
          align-items: center;
          position: absolute;
          bottom: 10px;
          left: 10px;
          font-size: 0.9rem;
          svg {
            font-size: 0.7rem;
            margin-right: 5px;
          }
        }
        &:hover {
          z-index: 2;
          cursor: pointer;
          transform: scale(1.2);
          box-shadow: 0 0 2rem rgba($color: #000000, $alpha: 0.5);
          .cr-video-card-play {
            background-color: #fff;
            color: #000;
          }
          .cr-video-card-stat {
            // animation: smoothSlide 0.3s;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .crustflix {
    .cr-video {
      grid-template-columns: 1fr 1fr;
    }
    .crustflix-main {
      .cr-content {
        .cr-management {
          width: auto;
          & > div {
            flex-direction: column;
            align-items: center;
            .m-card {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
